import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Breadcrumbs,
  Link,
  Button,
  Paper,
  Avatar,
  IconButton,
  Chip,
} from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import MailLockOutlinedIcon from "@mui/icons-material/MailLockOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import BasicTable from "../../components/table/table";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { color, styled } from "@mui/system";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
import { useSearchParams } from "react-router-dom";
import {
  formatDateToLongForm,
  formatDateWithTime,
} from "../../components/utils/DateFormatter";
import ConfirmationDialog from "../../components/utils/Dialog";
import LoadingBackdrop from "../../components/utils/loader";
import { hasPermission } from "../../components/utils/permissions/permissions";

const UserDetails = () => {
  const { makeApiCall } = useApiRequest();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState("");
  const id = searchParams.get("id");
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleCancel = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const config = {
    fromExercise: true, // or true based on your logic
    title: "",
    chartTitle: "Top Used Exercises",
    card1Title: "Available Exercises",
    card1Count: "10 000 Exercises", // Example of user count
    card2Title: "Used Exercises",
    card2Count: "10 000 Exercises", // Example of user count
    card3Title: "Archived Exercises",
    card3Count: "10 000 Exercises", // Example of user count

    card1Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.teal600}
      >
        <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
      </svg>
    ),
    card2Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.purple}
      >
        <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
      </svg>
    ),
    card3Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.red900}
      >
        <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
      </svg>
    ),
    card1Color: colors.teal600,
    card2Color: colors.purple,
    card3Color: colors.red900,
    card1IconBgColor: colors.teal50,
    card2IconBgColor: "#EEE1FF",
    card3IconBgColor: colors.red100,
    legend1: "Open",
    legend2: "Closed",
  };

  const NewsIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={colors.gray800}
    >
      <path d="M160-120q-33 0-56.5-23.5T80-200v-560q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v560q0 33-23.5 56.5T800-120H160Zm0-80h640v-560H160v560Zm80-80h480v-80H240v80Zm0-160h160v-240H240v240Zm240 0h240v-80H480v80Zm0-160h240v-80H480v80ZM160-200v-560 560Z" />
    </svg>
  );

  const GoogleIcon = () => {
    return (
      <img
        src="https://d3vdd33llamc8d.cloudfront.net/assets/icons/google.png"
        alt="google"
      />
    );
  };

  const FacebookIcon = () => {
    return (
      <img
        src="https://d3vdd33llamc8d.cloudfront.net/assets/icons/facebook.png"
        alt="facebook"
      />
    );
  };

  const AppleIcon = () => {
    return (
      <img
        src="https://d3vdd33llamc8d.cloudfront.net/assets/icons/apple.png"
        alt="apple"
        style={{ marginLeft: 25, marginRight: 25 }}
      />
    );
  };
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await makeApiCall(routes.user.get(id), "GET");
      setData(response.data.user);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const userData = {
    name: data?.username,
    email: data?.email,
    image: data?.image,
    subscribedToNewsletter: data?.newsletterMemberId ? true : false,
    joinedOn: formatDateWithTime(data?.createdOn),
    emailVerifiedOn: formatDateWithTime(data?.emailVerifiedOn),
    lastSignIn: formatDateWithTime(data?.lastSignIn),
    activeSessions: data?.activeSessionsCount,
    workouts: data?.workouts,
  };
  const rows = userData?.workouts?.map((workout) => ({
    id: workout.id,
    name: workout.title,
    sections: workout.sections.length, // Assuming you want the count of sections
    created: formatDateToLongForm(workout.createdOn), // Format the date
  }));
  const columns = [
    { field: "id", label: "ID", style: { pr: 8 } },
    { field: "name", label: "Name" },
    { field: "sections", label: "Sections" },
    { field: "created", label: "Created" },
  ];

  const StyledChip = styled(Chip)(({ theme }) => ({
    // width: "75px",
    height: "37px",
    borderRadius: "25px",
    backgroundColor: data?.deletedOn ? colors.red100 : colors.green100,
    color: data?.deletedOn ? colors.red900 : colors.green900,
  }));

  // const handleRestrictUser = async () => {
  //   setIsLoading(true);
  //   try {
  //     const payload = {
  //       userId: id,
  //       restrict: data?.deletedOn ? false : true,
  //     };

  //     const response = await makeApiCall(routes.user.put, "PUT", payload);
  //     // setData(...data, data?.deletedOn ? null : (data.deletedOn = new Date()));

  //     if (response.status) {
  //       // Update the data state based on the action
  //       setData((prevData) => ({
  //         ...prevData,
  //         deletedOn: payload.restrict ? new Date() : null,
  //       }));
  //     }
  //   } catch (e) {
  //   } finally {
  //     setOpenDialog(false);
  //     setIsLoading(false);
  //   }
  // };

  const storedUser = JSON.parse(localStorage.getItem("user"));
  const userRole = storedUser?.role;
  return (
    <Box sx={{ mx: "4%" }}>
      <LoadingBackdrop isLoading={isLoading} />

      <Grid display="flex" justifyContent={"space-between"} mt={5}>
        <Box>
          <Typography
            textAlign={"left"}
            fontSize={fonts.heading1}
            fontWeight={600}
            width={"100px"}
            mt={0}
            sx={{
              background: "linear-gradient(90deg, #74D0A9 0%, #9388D3 93.63%);",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            Users
          </Typography>
          <Typography
            mt={0.5}
            textAlign={"left"}
            gutterBottom
            color={colors.gray400}
          >
            See all the users that are making use of the web app
          </Typography>
        </Box>
      </Grid>
      <Box display="flex" justifyContent={"space-between"} mt={3}>
        <Box>
          <Breadcrumbs>
            <Link
              color={colors.gray400}
              sx={{ textDecoration: "none" }}
              href="/users"
            >
              All Users
            </Link>

            <Typography color="text.secondary">{userData.name}</Typography>
          </Breadcrumbs>
        </Box>
        <Box>
          {hasPermission(userRole, "SendUserEmail") && (
            <Button
              variant="contained"
              fontSize={fonts.heading4}
              size="large"
              endIcon={<ForwardToInboxOutlinedIcon />}
              sx={{
                textTransform: "none",
                background: colors.teal400,
                color: colors.teal50,
                fontWeight: 400,
                height: "40px",
                width: "150px",
                px: 2,
                mr: 2,
              }}
              onClick={() => {
                window.location.href = `mailto:${userData.email}`;
              }}
            >
              Send Email
            </Button>
          )}

          {/* {hasPermission(userRole, "RestrictUser") && (
            <Button
              variant="contained"
              fontSize={fonts.heading4}
              size="large"
              endIcon={<FlagOutlinedIcon />}
              sx={{
                textTransform: "none",
                background: data?.deletedOn ? colors.green900 : colors.red900,
                color: data?.deletedOn ? colors.green100 : colors.red100,
                fontWeight: 400,
                height: "40px",
                width: data?.deletedOn ? "auto" : "150px",
                px: 2,
              }}
              onClick={handleOpenDialog}
            >
              {data?.deletedOn ? "Unrestrict User" : "Restrict User"}
            </Button>
          )} */}
        </Box>
      </Box>

      <Paper elevation={0} sx={{ mt: 6, mb: 4 }}>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box
            mr={"1%"}
            mb={2}
            sx={{ position: "relative", display: "inline-block" }}
          >
            <Avatar
              sx={{
                width: 100,
                height: 100,
                mr: 1,
                border: "4.2px solid transparent",
                // Create gradient border
                backgroundImage:
                  "linear-gradient(white, white), linear-gradient(148.43deg, #9747FF -2.38%, #00796B 40.71%)",
                backgroundOrigin: "border-box",
                backgroundClip: "content-box, border-box",
              }}
              src={userData?.image || ""}
              alt="Profile"
            />
            <IconButton
              color="primary"
              sx={{
                position: "absolute",
                right: 7,
                bottom: 1,
                backgroundColor: colors.teal700,
                "&:hover": { backgroundColor: "primary.main" },
                color: colors.teal50,
                borderRadius: "50%",
              }}
            >
              <CameraAltOutlinedIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
              />
            </IconButton>
          </Box>{" "}
          <Box mr={"1%"} mb={1}>
            <Typography
              textAlign={"left"}
              fontSize={fonts.heading3}
              color={colors.gray800}
              fontWeight={600}
            >
              {userData.name}
            </Typography>
          </Box>
          <StyledChip label={data?.deletedOn ? "Deleted" : "Active"} />
        </Box>
      </Paper>
      <Typography
        textAlign={"left"}
        fontSize={fonts.heading2}
        color={colors.gray800}
        fontWeight={600}
      >
        Account Details
      </Typography>
      <Paper elevation={0} sx={{ mt: 5, mb: 4 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item md={4} sm={3}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Display Name
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <AccountCircleOutlinedIcon sx={{ color: colors.gray800 }} />
              <Typography display={"inline-block"} ml={2}>
                {userData.name}
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={4} sm={5}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Email
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <AlternateEmailOutlinedIcon sx={{ color: colors.gray800 }} />
              <Typography display={"inline-block"} ml={2}>
                {userData.email}
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={4} sm={3}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Subscribed To Newsletter
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <NewsIcon />
              <Typography display={"inline-block"} ml={2}>
                {userData.subscribedToNewsletter ? "Yes" : "No"}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} sx={{ mt: 5, mb: 4 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item md={4} sm={3}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Joined On
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <EventNoteOutlinedIcon sx={{ color: colors.gray800 }} />
              <Typography display={"inline-block"} ml={2}>
                {userData.joinedOn}
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={4} sm={3}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Email Verified On
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <MailLockOutlinedIcon sx={{ color: colors.gray800 }} />
              <Typography display={"inline-block"} ml={2}>
                {userData.emailVerifiedOn}
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={4} sm={3}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Last Sign In
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <LoginOutlinedIcon />
              <Typography display={"inline-block"} ml={2}>
                {userData.lastSignIn}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} sx={{ mt: 5, mb: 8 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item md={4} sm={3}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Signed In On
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <KeyOutlinedIcon sx={{ color: colors.gray800 }} />
              <Typography display={"inline-block"} ml={2}>
                0{userData.activeSessions} Active Sessions
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={4} sm={3}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Socials Connected
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              {data?.facebookUserId && <FacebookIcon />}
              {data?.appleUserId && <AppleIcon />}
              {data?.googleUserId && <GoogleIcon />}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Typography
        textAlign={"left"}
        fontSize={fonts.heading2}
        color={colors.gray800}
        fontWeight={600}
      >
        Workouts
      </Typography>
      <Box mt={4}>
        <BasicTable columns={columns} rows={rows} />
      </Box>
      {/* <ConfirmationDialog
        open={openDialog}
        onClose={handleCancel}
        onConfirm={handleRestrictUser}
        title={data?.deletedOn ? "Unrestrict" : "Restrict"}
        content={
          data?.deletedOn
            ? "Are you sure you want to unrestrict the user?"
            : "Are you sure you want to restrict the user?"
        }
        cancelButtonText="Cancel"
        confirmButtonText={data?.deletedOn ? "Unrestrict" : "Restrict"}
        cancelButtonProps={{ sx: { color: colors.gray500 } }}
        confirmButtonProps={{
          sx: { color: data?.deletedOn ? colors.green900 : colors.red900 },
        }}
      /> */}
    </Box>
  );
};

export default UserDetails;
