// src/styles/colors.js

const fonts = {
  heading1: "34px",
  heading2: "24px",
  heading3: "20px",
  heading4: "16px",
  body: "14px",
  info: "13px",
  // Add more colors as needed
};

export default fonts;
