import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  Breadcrumbs,
  Link,
  TextField,
  Alert,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import DividerLine from "../../components/utils/DividerLine";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileUploader from "../../components/utils/FileUploader";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import edit from "../../assets/edit.svg";
import { SubjectOutlined } from "@mui/icons-material";
import chatImages from "../../assets/messageChatImage.svg";

import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ReplyMessageModal from "../../components/utils/ReplyMessageModal";
import MessageSection from "../../components/messages/Messages";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import useApiRequest from "../../api/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../../api/routes";
import {
  formatDateToLongForm,
  formatDateWithTime,
} from "../../components/utils/DateFormatter";
import LoadingBackdrop from "../../components/utils/loader";
import { hasPermission } from "../../components/utils/permissions/permissions";

const TicketDetails = () => {
  const { makeApiCall } = useApiRequest();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [userData, setUserData] = useState("");
  const id = searchParams.get("id");
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const DomainIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={colors.gray800}
    >
      <path d="M280-160v-441q0-33 24-56t57-23h439q33 0 56.5 23.5T880-600v320L680-80H360q-33 0-56.5-23.5T280-160ZM81-710q-6-33 13-59.5t52-32.5l434-77q33-6 59.5 13t32.5 52l10 54h-82l-7-40-433 77 40 226v279q-16-9-27.5-24T158-276L81-710Zm279 110v440h280v-160h160v-280H360Zm220 220Z" />
    </svg>
  );

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await makeApiCall(routes.ticket.get(id), "GET");
      setData(response.data.ticket);
      setUserData(response.data.ticket.createdByUser);
      setStatus(response.data.ticket.status);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const userRole = storedUser?.role;
  const [status, setStatus] = useState("Open");
  const handleStatusChange = async (event) => {
    setStatus(event.target.value);
    try {
      const payload = {
        previousStatus: status,
        status: event.target.value,
      };

      const response = await makeApiCall(routes.ticket.put(id), "PUT", payload);
    } catch (e) {
    } finally {
    }
  };
  const messages = data?.messages?.map((message) => ({
    sender: message?.sentByUser?.username,
    senderId: message?.sentByUser?.id,
    timestamp: formatDateWithTime(message?.createdOn),
    attachments: message?.uploadCdnUrls || [],
    content: message?.messageText,
    isAdmin: message?.sentByUser?.role !== "user",
  }));

  const handleViewUser = async () => {
    navigate(`/users/details?id=${userData.id}`);
  };
  return (
    <Box sx={{ mx: "4%" }}>
      <LoadingBackdrop isLoading={isLoading} />

      <Grid display="flex" justifyContent={"space-between"} mt={5}>
        <Box>
          <Typography
            textAlign={"left"}
            fontSize={fonts.heading1}
            fontWeight={600}
            width={"300px"}
            mt={0}
            sx={{
              background: "linear-gradient(90deg, #74D0A9 0%, #9388D3 93.63%);",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            Support Tickets
          </Typography>
          <Typography
            mt={0.5}
            textAlign={"left"}
            gutterBottom
            color={colors.gray400}
          >
            See all the users that are making use of the web app
          </Typography>
        </Box>
      </Grid>
      <Box display="flex" justifyContent={"space-between"} mt={3}>
        <Box>
          <Breadcrumbs>
            <Link
              color={colors.gray400}
              sx={{ textDecoration: "none" }}
              href="/support"
            >
              All Tickets
            </Link>

            <Typography color="text.secondary">Details</Typography>
          </Breadcrumbs>
        </Box>
        <Button
          variant="contained"
          fontSize={fonts.heading4}
          size="large"
          endIcon={<OpenInNewOutlinedIcon />}
          sx={{
            textTransform: "none",
            background: colors.teal400,
            color: colors.teal50,
            fontWeight: 400,
            height: "40px",
            width: "150px",
            px: 2,
          }}
          onClick={handleViewUser}
        >
          View User
        </Button>
      </Box>

      <Paper elevation={0} sx={{ mt: 6, mb: 4 }}>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box
            mr={"2%"}
            mb={2}
            sx={{ position: "relative", display: "inline-block" }}
          >
            <Avatar
              sx={{
                width: 100,
                height: 100,
                mr: 1,
                border: "4.2px solid transparent",
                // Create gradient border
                backgroundImage:
                  "linear-gradient(white, white), linear-gradient(148.43deg, #9747FF -2.38%, #00796B 40.71%)",
                backgroundOrigin: "border-box",
                backgroundClip: "content-box, border-box",
              }}
              src={userData?.image || ""}
              alt="Profile"
            />
            <IconButton
              color="primary"
              sx={{
                position: "absolute",
                right: 7,
                bottom: 1,
                backgroundColor: colors.teal700,
                "&:hover": { backgroundColor: "primary.main" },
                color: colors.teal50,
                borderRadius: "50%",
              }}
            >
              <CameraAltOutlinedIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
              />
            </IconButton>
          </Box>{" "}
          <Box mr={"5%"} mb={2}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Display Name
            </Typography>
            <Box display="flex" alignItems="center" mt={2}>
              <AccountCircleOutlinedIcon sx={{ color: "text.primary" }} />
              <Typography ml={2}>{userData?.username}</Typography>
            </Box>
          </Box>
          <Box mr={3} mb={2}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Email
            </Typography>
            <Box display="flex" alignItems="center" mt={2}>
              <AlternateEmailOutlinedIcon sx={{ color: "text.primary" }} />
              <Typography ml={2}>{userData?.email}</Typography>
            </Box>
          </Box>
          <Box flexGrow={1} display="flex" justifyContent="flex-end" mb={2}>
            <FormControl>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                value={status}
                onChange={handleStatusChange}
                label="Status"
                disabled={!hasPermission(userRole, "UpdateSupportTickets")}
                sx={{
                  width: {
                    lg: "250px",
                    md: "150px",
                  },
                  textAlign: "left",
                }}
              >
                <MenuItem value="open">Submitted</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Paper>
      <Paper elevation={0} sx={{ mt: 3, mb: 4 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={2} sm={3}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Submitted
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <EventNoteOutlinedIcon sx={{ color: colors.gray800 }} />
              <Typography display={"inline-block"} ml={2}>
                {formatDateToLongForm(data?.createdOn)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item lg={2} sm={2}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Closed
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <EventNoteOutlinedIcon sx={{ color: colors.gray800 }} />
              <Typography display={"inline-block"} ml={2}>
                {formatDateToLongForm(data?.closedOn)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Closed By
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <AccountCircleOutlinedIcon sx={{ color: colors.gray800 }} />
              <Typography display={"inline-block"} ml={2}>
                {data?.closedByUser?.username}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Typography textAlign={"left"} variant="subtitle2" color="text.secondary">
        Domain
      </Typography>
      <Box mt={2}>
        <Typography
          sx={{
            mt: 0,
            width: {
              lg: "70%",
              md: "60%",
            },
            display: "flex",
            justifyContent: "start",
          }}
        >
          <DomainIcon sx={{ color: colors.gray800 }} />{" "}
          <Typography display={"inline-block"} ml={3}>
            {data?.domain}
          </Typography>
        </Typography>
      </Box>

      <Typography
        mt={5}
        textAlign={"left"}
        variant="subtitle2"
        color="text.secondary"
      >
        Subject
      </Typography>
      <Box mt={2}>
        <Typography
          sx={{
            mt: 0,
            width: {
              lg: "70%",
              md: "60%",
            },
            display: "flex",
            justifyContent: "start",
          }}
        >
          <SubjectOutlined sx={{ color: colors.gray800 }} />{" "}
          <Typography display={"inline-block"} ml={3}>
            {data?.subject}
          </Typography>
        </Typography>
      </Box>

      {hasPermission(userRole, "UpdateSupportTickets") && (
        <MessageSection messages={messages} onReply={handleOpenModal} />
      )}
      <ReplyMessageModal open={isModalOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default TicketDetails;
