import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PieChart } from "@mui/x-charts/PieChart";
import { valueFormatter } from "./webUsage";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import { Paper } from "@mui/material";

export default function MuiPieChart({ config }) {
  const [itemData, setItemData] = React.useState(null);
  const [chartSize, setChartSize] = React.useState({ width: 710, height: 400 });
  const [isMobile, setIsMobile] = React.useState(false);
  const [isWidth900, setIsWidth900] = React.useState(false);

  // Dynamically adjust the pie chart size based on screen width
  const handleResize = React.useCallback(() => {
    const newWidth = Math.min(window.innerWidth * 0.54, 710); // Adjusts with screen size, max 710px
    const newHeight = newWidth * 0.63; // Keep aspect ratio of 16:9 or adjust as needed
    setChartSize({ width: newWidth, height: newHeight });
    setIsMobile(window.innerWidth < 1400);
    setIsWidth900(window.innerWidth < 900);
  }, []);

  React.useEffect(() => {
    handleResize(); // Set the initial size
    window.addEventListener("resize", handleResize); // Add listener for resize

    return () => window.removeEventListener("resize", handleResize); // Cleanup listener on unmount
  }, [handleResize]);

  // Transform graphData into a format suitable for PieChart
  const series = React.useMemo(() => {
    if (!config.graphData || config.graphData.length === 0) {
      // Default data when graphData is empty
      return [
        {
          innerRadius: 0,
          outerRadius: 140,
          id: "primary-series",
          data: [
            {
              id: "default",
              value: 1,
              label: "N/A",
              usageCount: 0, // Original usage count
            },
          ],
          valueFormatter: () => "0", // Display "0" for usage count
        },
      ];
    }

    // Check if all values are 0
    const allZeros = config.graphData.every((item) => item.usageCount === 0);

    const normalizedData = config.graphData.map((item) => ({
      id: item.id,
      value: allZeros ? 1 : item.usageCount,
      label: item.title,
      usageCount: item.usageCount, // Store the original usage count
    }));

    return [
      {
        innerRadius: 0,
        outerRadius: 140,
        id: "primary-series",
        data: normalizedData,
        valueFormatter: (value, itemData) => {
          // Return the original usage count
          return value.usageCount ? value.usageCount : 0;
        },
      },
    ];
  }, [config.graphData]);

  return (
    <Paper
      elevation={0}
      sx={{
        width: isMobile ? "100%" : "95%",
        boxShadow: "0px 4px 20px 0px #00000014",
        pt: 5,
        pb: 2,
        pr: 4,
        height: "550px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        mt={-3}
        borderRadius={"8px"}
      >
        <Typography
          color={colors.gray800}
          fontSize={fonts.heading3}
          fontWeight={600}
          ml={2}
        >
          {config.chartTitle || "Data Visualization"}
        </Typography>
      </Box>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 0, md: 4 }}
        sx={{ width: "100%", mt: 5 }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <PieChart
            series={series}
            width={isWidth900 ? 650 : chartSize.width}
            height={isWidth900 ? 400 : chartSize.height}
            slotProps={{
              legend: {
                hidden: false,
                padding: isMobile ? -27 : -10,
                itemGap: 25,
                markGap: 15,
                itemMarkWidth: 20,
              },
            }}
            onItemClick={(event, d) => setItemData(d)}
          />
        </Box>
      </Stack>
    </Paper>
  );
}
