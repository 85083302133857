import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Alert } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import LandingPageSection from "../../components/dashboard/LandingPageSections";

import WebAppSection from "../../components/dashboard/WebAppSection";
import ExercisesSection from "../../components/dashboard/ExercisesSection";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import useApiRequest from "../../api/api";
import { useNavigate } from "react-router";
import routes from "../../api/routes";
import useFetchSectionData from "../../hooks/useFetchSessionHook";
import LoadingBackdrop from "../../components/utils/loader";
import SmallLoader from "../../components/utils/small_loader";

const Dashboard = () => {
  const { makeApiCall } = useApiRequest();
  const navigate = useNavigate();
  const [landingPageData, setLandingPageData] = useState({});
  const [activeUsersData, setActiveUsersData] = useState({});
  const [workoutsData, setWorkoutsData] = useState({});
  const [ticketData, setTicketData] = useState({});
  const [exerciseData, setExerciseData] = useState({});
  const [period, setPeriod] = useState("today");
  const [config, setConfig] = useState({
    fromDashboard: true,
    title: "Landing Page",
    chartTitle: "Visitors",
    card1Title: "Newsletter Subscriptions",
    // card1Count: "10 000 Users",
    card2Title: "Socials Redirect",
    // card2Count: "10 000 Redirects",
    card1Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.teal600}
      >
        <path d="M280-280q-33 0-56.5-23.5T200-360v-400q0-33 23.5-56.5T280-840h560q33 0 56.5 23.5T920-760v400q0 33-23.5 56.5T840-280H280Zm280-188L280-663v303h560v-303L560-468Zm0-98 280-194H280l280 194ZM120-120q-33 0-56.5-23.5T40-200v-500h80v500h660v80H120Zm720-546v-94H280v94-94h560v94Z" />
      </svg>
    ),
    card2Icon: <ShareOutlinedIcon sx={{ color: colors.purple }} />,
    card1Color: colors.teal600,
    card2Color: colors.purple,
    card1IconBgColor: colors.teal50,
    card2IconBgColor: "#EEE1FF",
    // legends: [],
  });

  const sections = [
    {
      endpoint: routes.dashboard.landing_page({ period }),
      config,
      key: "landing",
    },
    {
      endpoint: routes.dashboard.active_users({ period }),
      config,
      key: "activeUsers",
    },
    {
      endpoint: routes.dashboard.workouts({ period }),
      config,
      key: "workouts",
    },
    {
      endpoint: routes.dashboard.support,
      config,
      key: "support",
    },
    {
      endpoint: routes.dashboard.exercise,
      config,
      key: "exercise_summary",
    },
  ];

  const { data, updateSectionPeriod, loading } = useFetchSectionData(
    sections,
    period
  );

  // For landing page
  const handleLandingPeriodChange = (newPeriod) => {
    updateSectionPeriod("landing", newPeriod);
  };

  // For WebApp section
  const handleWebAppPeriodChange = (section, newPeriod) => {
    updateSectionPeriod(section, newPeriod); // Only update the specific section
  };

  // For Exercises section
  const handleExercisesPeriodChange = (newPeriod) => {
    updateSectionPeriod("exercise", newPeriod);
  };

  const storedUser = JSON.parse(localStorage.getItem("user"));
  const getComparisonText = () => {
    let periodText = "yesterday";

    if (period === "thisMonth") {
      periodText = "last month";
    } else if (period === "thisYear") {
      periodText = "last year";
    }

    if (landingPageData?.landing?.percentChangeInViewership > 0) {
      return `${landingPageData.landing?.percentChangeInViewership}% more viewers than ${periodText}`;
    } else if (landingPageData?.landing?.percentChangeInViewership < 0) {
      return `${Math.abs(
        landingPageData?.landing?.percentChangeInViewership
      )}% less viewers than ${periodText}`;
    } else {
      return `No change in viewers compared to ${periodText}`;
    }
  };

  return (
    <Box sx={{ mx: "4%" }}>
      <Typography
        textAlign={"left"}
        fontSize={fonts.heading1}
        width={"300px"}
        fontWeight={600}
        mt={5}
        sx={{
          background: "linear-gradient(90deg, #74D0A9 0%, #9388D3 93.63%);",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
          textFillColor: "transparent",
        }}
      >
        Dashboard
        <SmallLoader isLoading={loading} />
      </Typography>
      <Typography
        mt={0.5}
        textAlign={"left"}
        gutterBottom
        color={colors.gray400}
      >
        Quickly view through the most important visually represented insights
      </Typography>

      <Grid container spacing={3}>
        <LandingPageSection
          config={data.landing}
          onPeriodChange={handleLandingPeriodChange}
        />

        <Grid item xs={12}>
          <Alert
            severity={
              landingPageData?.landing?.percentChangeInViewership > 0
                ? "success"
                : landingPageData?.landing?.percentChangeInViewership < 0
                ? "error"
                : "info"
            }
            iconMapping={{
              success: (
                <InfoOutlinedIcon
                  fontSize="inherit"
                  sx={{
                    color: colors.teal900,
                  }}
                />
              ),
              error: (
                <InfoOutlinedIcon
                  fontSize="inherit"
                  sx={{
                    color: colors.red900,
                  }}
                />
              ),
              info: (
                <InfoOutlinedIcon
                  fontSize="inherit"
                  sx={{
                    color: colors.gray800,
                  }}
                />
              ),
            }}
            sx={{
              mb: 1,
              background:
                landingPageData?.landing?.percentChangeInViewership > 0
                  ? colors.teal50
                  : landingPageData?.landing?.percentChangeInViewership < 0
                  ? colors.red50
                  : colors.gray200,
              color:
                landingPageData?.landing?.percentChangeInViewership > 0
                  ? colors.teal900
                  : landingPageData?.landing?.percentChangeInViewership < 0
                  ? colors.red900
                  : colors.gray800,
              border: "2px solid",
              borderColor:
                landingPageData?.landing?.percentChangeInViewership > 0
                  ? colors.teal900
                  : landingPageData?.landing?.percentChangeInViewership < 0
                  ? colors.red900
                  : colors.gray800,
              textAlign: "left",
            }}
          >
            {getComparisonText()}
          </Alert>
        </Grid>

        <WebAppSection
          configActiveUsersData={data.activeUsers}
          configWorkoutsData={data.workouts}
          configSupportData={data.support}
          onPeriodChange={handleWebAppPeriodChange}
        />
        <ExercisesSection
          configExerciseData={data.exercise_summary}
          onPeriodChange={handleExercisesPeriodChange}
        />
      </Grid>
    </Box>
  );
};

export default Dashboard;
