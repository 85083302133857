import React from "react";
import { Paper, Box, Typography, Select, MenuItem } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";

const WorkoutsChart = ({ config, onPeriodChange }) => {
  const handleSelectChange = (event) => {
    const selectedPeriod = event.target.value;
    onPeriodChange(selectedPeriod); // Trigger the callback to update the main page
  };
  const workoutData = config?.graphData
    ? config?.graphData.legendOneDataPoints.map((point, index) => {
        const dataPoint = {
          name: config?.graphData.xLabels[index],
          [config?.legend1]: point, // Set the first legend data point
        };

        // Check for additional legends and add them dynamically
        if (config?.graphData.legendTwoDataPoints) {
          dataPoint[config?.legend2] =
            config?.graphData.legendTwoDataPoints[index];
        }
        if (config?.graphData.legendThreeDataPoints) {
          dataPoint[config?.legend3] =
            config?.graphData.legendThreeDataPoints[index];
        }
        if (config?.graphData.legendFourDataPoints) {
          dataPoint[config?.legend4] =
            config?.graphData.legendFourDataPoints[index];
        }

        return dataPoint;
      })
    : [];
  const legendKeys = [
    config?.legend1,
    config?.legend2,
    config?.legend3,
    config?.legend4,
  ].filter(Boolean);

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        px: 2,
        py: 3,
        borderRadius: "8px",
        boxShadow: "0px 4px 20px 0px #00000014",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography
          fontSize={fonts.heading3}
          color={colors.gray800}
          fontWeight={600}
          ml={2}
        >
          Workouts
        </Typography>
        {config?.fromDashboard && (
          <Select
            defaultValue="today"
            sx={{
              pr: 1.5,
              fontSize: fonts.heading4,
              color: "rgba(0, 0, 0, 0.38)",
            }}
            onChange={handleSelectChange}
            size="small"
          >
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="thisMonth">This Month</MenuItem>
            <MenuItem value="thisYear">This Year</MenuItem>
          </Select>
        )}
      </Box>
      <Box sx={{ height: 300 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={workoutData}>
            <CartesianGrid style={{ opacity: 0 }} strokeDasharray="3" />
            <XAxis
              style={{ opacity: 0.5 }}
              fontSize={12}
              tickMargin={10}
              interval={0}
              dataKey="name"
              textAnchor="end" // Align text after rotation
            />
            <YAxis style={{ opacity: 0.5 }} />
            <Tooltip />
            {legendKeys.map((key, index) => (
              <Bar
                key={key}
                dataKey={key}
                stackId="a"
                fill={index === 0 ? colors.teal200 : colors.red300}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default WorkoutsChart;
