import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  cancelButtonText,
  confirmButtonText,
  cancelButtonProps,
  confirmButtonProps,
  disableOnClose = false,
}) => {
  const handleCancel = () => {
    if (disableOnClose) {
      onConfirm(false); // Call handleVerify(false)
    } else {
      onClose(); // Close the dialog
    }
  };
  const handleConfirm = () => {
    if (disableOnClose) {
      onConfirm(true); // Call handleVerify(false)
    } else {
      onConfirm(); // Close the dialog
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      fullWidth
    >
      {title && (
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      )}
      {content && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {cancelButtonText && (
          <Button onClick={handleCancel} {...cancelButtonProps}>
            {cancelButtonText}
          </Button>
        )}
        {confirmButtonText && (
          <Button onClick={handleConfirm} {...confirmButtonProps}>
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
