export const exportToCSV = (data, filename) => {
  if (!data || data.length === 0) {
    console.error("No data to export.");
    return;
  }

  // Extract keys from the first object to use as column headers
  const headers = Object.keys(data[0]);

  // Create a CSV string
  const csvRows = [
    headers.join(","), // Add the headers row
    ...data.map(
      (row) =>
        headers
          .map((header) => {
            const value = row[header];
            // Escape any values containing commas or double quotes
            if (
              typeof value === "string" &&
              (value.includes(",") || value.includes('"'))
            ) {
              return `"${value.replace(/"/g, '""')}"`; // Escape double quotes by doubling them
            }
            return value ?? ""; // Handle null/undefined values
          })
          .join(",") // Join values with commas
    ),
  ];

  const csvContent = csvRows.join("\n");

  // Create a blob and download the file
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const csvFilename = `${filename}.csv`;
  link.href = URL.createObjectURL(blob);
  link.download = csvFilename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
