import React, { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import colors from "../../styles/colors";
import useWindowWidth from "../windowSize/WindowWidth";
import fonts from "../../styles/fontVars";
import OtpInput from "react-otp-input";
import WestSharpIcon from "@mui/icons-material/WestSharp";
const Otp = ({
  handleBack,
  handleContinue,
  isFromRegister,
  handleResend,
  isFromAdmin,
}) => {
  const [otp, setOtp] = useState("");

  const handleChange = (value) => {
    setOtp(value);
  };
  const [rememberMeForSevenDays, setRememberMeForSevenDays] = useState(false);

  const handleCheckboxChange = (event) => {
    setRememberMeForSevenDays(event.target.checked);
  };
  const resendHandle = () => {
    setTimeLeft(59);
    handleResend();
  };
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  const [timeLeft, setTimeLeft] = useState(59); // Initial time in seconds

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [timeLeft]);

  return (
    <Box sx={{ width: isMobile ? "100%" : "100%" }}>
      {!isFromAdmin && (
        <Typography
          sx={{
            color: colors.gray600,
            display: "flex",
            justifyContent: isMobile ? "center" : "left",
            textAlign: "left",
            mt: 0,
            cursor: "pointer",
          }}
          // variant={windowWidth <= 768 ? "h5" : "h4"}
          fontSize={fonts.body}
          gutterBottom
          onClick={handleBack}
        >
          <WestSharpIcon /> <Typography sx={{ marginLeft: 1 }}>Back</Typography>{" "}
        </Typography>
      )}
      <Typography
        sx={{
          color: colors.gray900,
          display: "flex",
          justifyContent: isMobile ? "center" : "left",
          textAlign: "left",
          mt: 5,
        }}
        // variant={windowWidth <= 768 ? "h5" : "h4"}
        fontWeight="bold"
        fontSize={fonts.heading1}
        gutterBottom
      >
        {isFromRegister ? "Verify Email" : "Forgot Password?"}
      </Typography>
      <Typography
        sx={{
          ml: isMobile ? 1 : 0,
          display: "flex",
          justifyContent: "left",
          textAlign: "left",
          width: "280px",
          fontSize: fonts.heading2,
          fontWeight: 600,
          color: colors.gray900,
          mt: 7,
        }}
        // variant={isMobile ? "body2" : fonts.body}

        gutterBottom
      >
        Enter the OTP
      </Typography>

      <Box marginLeft={"-2%"} marginTop={7}>
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            width: "14%",
            height: "3.5rem",
            margin: "0.8rem",
            border: `1px solid ${colors.gray500}`, // Border color
            borderRadius: "4px",
            fontSize: "1.5rem",
            textAlign: "center",
          }}
          containerStyle={{
            justifyContent: "left",
          }}
        />
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          component="span"
          display={"flex"}
          justifyContent={"left"}
          sx={{
            fontSize: isMobile ? "11px" : fonts.info,
            ml: isMobile ? 2 : 0,
            textAlign: "left",
          }}
        >
          Check your email for the code.
        </Typography>
        <Typography
          component="span"
          display={"flex"}
          justifyContent={"left"}
          sx={{
            fontSize: isMobile ? "11px" : fonts.info,
            ml: isMobile ? 2 : 0,
            color: colors.teal600,
            textAlign: "left",
            mr: 1.5,
            cursor: timeLeft === 0 ? "pointer" : "default",
            "&:hover": {
              textDecoration: timeLeft === 0 ? "underline" : "none",
            },
          }}
          onClick={resendHandle}
        >
          {timeLeft > 0
            ? `Resend in 0:${timeLeft < 10 ? `0${timeLeft}` : timeLeft}`
            : "Resend"}
        </Typography>
      </Box>
      {!isFromAdmin && (
        <FormControlLabel
          control={
            isMobile ? (
              <Box sx={{ width: 0, height: 0 }} />
            ) : (
              <Checkbox
                name="remember"
                checked={rememberMeForSevenDays}
                onChange={handleCheckboxChange}
              />
            )
          }
          sx={{
            display: "flex",
            justifyContent: "left",
            textAlign: "left",
            fontSize: isMobile ? "18px" : "24px",
          }}
          label={
            <Typography
              component="span"
              sx={{
                fontSize: isMobile ? "11px" : "16px",
                ml: isMobile ? 2 : 0,
              }}
            >
              Remember me for 7 days.
            </Typography>
          }
        />
      )}

      <Button
        fullWidth
        variant="contained"
        onClick={() => handleContinue(otp, rememberMeForSevenDays)}
        sx={{
          mt: 9,
          py: 1,
          background: colors.teal400,
          height: isMobile ? "45px" : "60px",
          borderRadius: "4px",
          textTransform: "none",
          fontSize: isMobile ? 12 : fonts.heading4,
          letterSpacing: 1,
          fontWeight: 400,
          transition: "box-shadow 0.3s ease",
          "&:hover": {
            boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.4)", // Drop shadow effect
          },
        }}
        disabled={otp.length !== 6}
      >
        {isFromRegister ? "Verify" : "Continue"}
      </Button>
    </Box>
  );
};

export default Otp;
