const routes = {
  account: {
    auth: {
      register: "/admin/account/register",
      login: "/admin/account/login",
      verify: "/admin/account/register/verify",
      logout: "/admin/account/logout",
      send_code: "/admin/account/register/send-code",
      password: {
        send_code: "/admin/account/password/send-code",
        verify_code: "/admin/account/password/verify-code",
        change_pw: "/admin/account/password",
      },
    },
    delete: "/admin/account",
  },
  super_admin_options: {
    get: (id) => `/admin/${id}`,
    delete: (id) => `/admin/${id}`,
    list: "/admin",
  },
  dashboard: {
    landing_page: ({ period = "today" }) =>
      `/analytic/landing-page?period=${period}`,
    active_users: ({ period = "today" }) =>
      `/analytic/user/active?period=${period}`,
    workouts: ({ period = "today" }) => `/analytic/workout?period=${period}`,
    support: "/analytic/ticket",
    exercise: "/analytic/exercise/summary",
  },
  profile: {
    account: "/admin/account",
    uploads: "/admin/account/uploads",
  },
  newsletter: {
    get_stats: ({ period = "today" }) =>
      `/analytic/newsletter?period=${period}`,
    search: ({ pageNumber = 1, sortBy = "name", ...restParams } = {}) => {
      const baseUrl = `/newsletter?pageNumber=${pageNumber}&sortBy=${sortBy}`;

      const additionalParams = Object.keys(restParams)
        .map((key) => `${key}=${encodeURIComponent(restParams[key])}`)
        .join("&");

      return additionalParams ? `${baseUrl}&${additionalParams}` : baseUrl;
    },
    export: "/newsletter/export",
  },

  user: {
    get_stats: ({ period = "thisYear" }) => `/analytic/user?period=${period}`,
    search: ({ pageNumber = 1, sortBy = "relevance", ...restParams } = {}) => {
      const baseUrl = `/user?pageNumber=${pageNumber}&sortBy=${sortBy}`;

      const additionalParams = Object.keys(restParams)
        .map((key) => `${key}=${encodeURIComponent(restParams[key])}`)
        .join("&");

      return additionalParams ? `${baseUrl}&${additionalParams}` : baseUrl;
    },
    export: "/user/export",
    get: (id) => `/user/${id}`,
    put: "/user/restriction",
  },
  exercise: {
    get_stats: ({ period = "thisYear" }) =>
      `/analytic/exercise?period=${period}`,
    search: ({ pageNumber = 1, sortBy = "relevance", ...restParams } = {}) => {
      const baseUrl = `/exercise?pageNumber=${pageNumber}&sortBy=${sortBy}`;

      const additionalParams = Object.keys(restParams)
        .map((key) => `${key}=${encodeURIComponent(restParams[key])}`)
        .join("&");

      return additionalParams ? `${baseUrl}&${additionalParams}` : baseUrl;
    },
    export: "/exercise/export",
    add: "/exercise",
    update: (id) => `/exercise/${id}`,
    get: (id) => `/exercise/${id}`,
  },

  ticket: {
    suggestion: {
      get_stats: ({ period = "thisYear" }) =>
        `/analytic/ticket/suggestion?period=${period}`,
      search: ({
        pageNumber = 1,
        sortBy = "relevance",
        ...restParams
      } = {}) => {
        const baseUrl = `/ticket?pageNumber=${pageNumber}&sortBy=${sortBy}`;

        const additionalParams = Object.keys(restParams)
          .map((key) => `${key}=${encodeURIComponent(restParams[key])}`)
          .join("&");

        return additionalParams ? `${baseUrl}&${additionalParams}` : baseUrl;
      },
      export: "/ticket/suggestion/export",
    },
    issue: {
      get_stats: ({ period = "thisYear" }) =>
        `/analytic/ticket/issue?period=${period}`,
      export: "/ticket/issue/export",
      message: "/ticket/issue/message",
      search: ({
        pageNumber = 1,
        sortBy = "relevance",
        ...restParams
      } = {}) => {
        const baseUrl = `/ticket?pageNumber=${pageNumber}&sortBy=${sortBy}`;

        const additionalParams = Object.keys(restParams)
          .map((key) => `${key}=${encodeURIComponent(restParams[key])}`)
          .join("&");

        return additionalParams ? `${baseUrl}&${additionalParams}` : baseUrl;
      },
    },

    get: (id) => `/ticket/${id}`,
    put: (id) => `/ticket/${id}`,
  },
};

export default routes;
