import React from "react";
import { Paper, Box, Typography, Select, MenuItem } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";

const ActiveUsersChart = ({ config, onPeriodChange }) => {
  const handleSelectChange = (event) => {
    const selectedPeriod = event.target.value;
    onPeriodChange(selectedPeriod); // Trigger the callback to update the main page
  };

  const activeUserData = config?.graphData
    ? config?.graphData.legendOneDataPoints.map((point, index) => {
        const dataPoint = {
          name: config?.graphData.xLabels[index],
          [config?.legend1]: point, // Set the first legend data point
        };

        return dataPoint;
      })
    : [];
  const legendKeys = [
    config?.legend1,
    config?.legend2,
    config?.legend3,
    config?.legend4,
  ].filter(Boolean);
  return (
    <Paper
      elevation={0}
      sx={{
        width: "98%",
        pr: 4,
        py: 3,
        boxShadow: "0px 4px 20px 0px #00000014",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography
          fontSize={fonts.heading3}
          color={colors.gray800}
          fontWeight={600}
          ml={2}
        >
          Users
        </Typography>
        {config?.fromDashboard && (
          <Select
            defaultValue="today"
            sx={{
              pr: 1.5,
              fontSize: fonts.heading4,
              color: "rgba(0, 0, 0, 0.38)",
            }}
            size="small"
            onChange={handleSelectChange}
          >
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="thisMonth">This Month</MenuItem>
            <MenuItem value="thisYear">This Year</MenuItem>
          </Select>
        )}
      </Box>

      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={activeUserData}>
          <CartesianGrid style={{ opacity: 0 }} strokeDasharray="3" />
          <XAxis
            style={{ opacity: 0.5 }}
            fontSize={12}
            tickMargin={10}
            interval={0}
            dataKey="name"
            textAnchor="end" // Align text after rotation
          />
          {/* <YAxis style={{ opacity: 0.5 }} /> */}
          <YAxis style={{ opacity: 0.5 }} fontSize={12} tickMargin={8} />
          <Tooltip />
          {legendKeys.map((legend, index) => (
            <Line
              key={index}
              type="linear"
              dataKey={legend}
              strokeWidth={2}
              stroke={
                index === 0
                  ? config?.card2Color
                  : index === 1
                  ? config?.card1Color
                  : index === 2
                  ? config?.legend3Color
                  : config?.legend4Color
              }
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default ActiveUsersChart;
