import React from "react";
import { Paper, Box, Typography, IconButton } from "@mui/material";
import VideoCallOutlinedIcon from "@mui/icons-material/VideoCallOutlined";
import MapsUgcOutlinedIcon from "@mui/icons-material/MapsUgcOutlined";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";

const StatCard = ({ title, value, icon, color }) => {
  const getIcon = () => {
    switch (icon) {
      case "video":
        return <VideoCallOutlinedIcon sx={{ color: colors.teal400 }} />;
      case "feedback":
        return <MapsUgcOutlinedIcon sx={{ color: colors.purple }} />;
      case "play":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill={colors.teal400}
          >
            <path d="m380-340 280-180-280-180v360Zm-60 220v-80H160q-33 0-56.5-23.5T80-280v-480q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v480q0 33-23.5 56.5T800-200H640v80H320ZM160-280h640v-480H160v480Zm0 0v-480 480Z" />
          </svg>
        );
      case "support":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill={colors.purple}
          >
            <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 412L346-160H160v-186L28-480l132-134v-186h186l134-132 134 132h186v186l132 134-132 134v186H614L480-28Zm0-112 100-100h140v-140l100-100-100-100v-140H580L480-820 380-720H240v140L140-480l100 100v140h140l100 100Zm0-340Z" />
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        mb: 2,
        boxShadow: "0px 4px 20px 0px #00000014",
        borderRadius: "6px",
        width: "97%",
      }}
    >
      <Box
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography mt={-2} color={colors.gray800}>
          {title}
        </Typography>
        <IconButton
          sx={{
            background:
              icon === "video" || icon === "play" ? colors.teal50 : "#EEE1FF",
            p: 2,
          }}
          size="small"
        >
          {getIcon()}
        </IconButton>
      </Box>
      <Typography
        display={"flex"}
        justifyContent={"start"}
        fontSize={fonts.heading2}
        fontWeight={500}
        color={color}
        pb={1}
        borderBottom={3}
        borderRadius={"6px"}
        mt={5}
        textAlign={"left"}
        pl={2}
      >
        {value}
      </Typography>
    </Paper>
  );
};

export default StatCard;
