import { DateTime } from "luxon";

export const formatDaysAgo = (dateString) => {
  if (!dateString) return "N/A";

  // Parse the UTC date string and create a date object
  const date = new Date(dateString);

  // Get current time in user's timezone
  const now = new Date();

  // Ensure both dates are compared in the same timezone
  const userTimezoneOffset = now.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
  const localDate = new Date(date.getTime() - userTimezoneOffset);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = now - localDate;

  // Convert to various time units
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  const differenceInDays = Math.floor(differenceInHours / 24);

  // Return appropriate time difference string
  if (differenceInDays > 0) {
    return differenceInDays === 1
      ? "1 day ago"
      : `${differenceInDays} days ago`;
  }

  if (differenceInHours > 0) {
    return differenceInHours === 1
      ? "1 hour ago"
      : `${differenceInHours} hours ago`;
  }

  if (differenceInMinutes > 0) {
    return differenceInMinutes === 1
      ? "1 minute ago"
      : `${differenceInMinutes} minutes ago`;
  }

  if (differenceInSeconds > 0) {
    return differenceInSeconds === 1
      ? "1 second ago"
      : `${differenceInSeconds} seconds ago`;
  }

  return "Just now";
};

export const formatTime = (totalTimeSeconds) => {
  const hours = Math.floor(totalTimeSeconds / 3600); // Convert to hours
  const minutes = Math.floor((totalTimeSeconds % 3600) / 60); // Convert to minutes
  const seconds = totalTimeSeconds % 60; // Remaining seconds

  let result = "";

  if (hours > 0) {
    result += `${hours} Hour${hours > 1 ? "s" : ""} `;
  }
  if (minutes > 0) {
    result += `${minutes} Min${minutes > 1 ? "s" : ""} `;
  }
  if (seconds > 0 || result === "") {
    result += `${seconds} Sec${seconds > 1 ? "s" : ""}`;
  }

  return result.trim();
};

export const formatDateToLongForm = (dateString) => {
  if (!dateString) return "N/A";

  // Parse the date string to create a date object
  const date = new Date(dateString);

  // Define the day, month, and year
  const day = date.getDate(); // Get the day of the month
  const month = date.toLocaleString("default", { month: "long" }); // Get the full month name
  const year = date.getFullYear(); // Get the year

  // Return the formatted string in the desired order
  return `${day} ${month}, ${year}`;
};

export const formatDateWithTime = (dateString) => {
  if (!dateString) return "N/A";

  // Parse the UTC date string using Luxon
  const date = DateTime.fromISO(dateString, { zone: "utc" });

  // Convert to the local time zone
  const localDate = date.setZone("local");

  // Format date and time in local machine time zone
  const formattedDate = localDate.toLocaleString(DateTime.DATE_FULL); // Example: December 4, 2024
  const formattedTime = localDate.toLocaleString(DateTime.TIME_SIMPLE); // Example: 11:42 PM

  return `${formattedDate} - ${formattedTime}`;
};

// export const formatDateWithTime = (dateString) => {
//   if (!dateString) return "N/A";

//   // Parse the date string to create a date object
//   const date = new Date(dateString);

//   // Define options for the date format
//   const dateOptions = { day: "numeric", month: "long", year: "numeric" };

//   // Define options for the time format
//   const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };

//   // Format date and time separately
//   const formattedDate = date.toLocaleDateString(undefined, dateOptions);
//   const formattedTime = date.toLocaleTimeString(undefined, timeOptions);

//   // Combine date and time
//   return `${formattedDate} - ${formattedTime}`;
// };
