import React from "react";
import { Paper, Box, Typography, IconButton } from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";

export default function SocialRedirect({ config }) {
  return (
    <>
      <Paper
        sx={{
          mt: config?.card1Title ? 6 : 0,
          boxShadow: "0px 4px 20px 0px #00000014",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl={2}
        >
          <Typography mt={-3} textAlign={"left"} color={colors.gray800}>
            {config?.card2Title}
          </Typography>
          <IconButton
            sx={{ background: config?.card2IconBgColor, p: 2, m: 2 }}
            size="small"
          >
            {config?.card2Icon}
          </IconButton>
        </Box>
        <Typography
          display={"flex"}
          justifyContent={"start"}
          fontSize={fonts.heading2}
          fontWeight={500}
          color={config?.card2Color}
          pb={1}
          borderBottom={3}
          borderRadius={"6px"}
          mt={config?.card1Title ? 2 : 25.5}
          textAlign={"left"}
          pl={2}
        >
          {config?.card2Count}
        </Typography>
      </Paper>
      {config?.card3Title && (
        <Paper sx={{ mt: 6, boxShadow: "0px 4px 20px 0px #00000014" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pl={2}
          >
            <Typography mt={-3} textAlign={"left"} color={colors.gray800}>
              {config?.card3Title}
            </Typography>
            <IconButton
              sx={{ background: config?.card3IconBgColor, p: 2, m: 2 }}
              size="small"
            >
              {config?.card3Icon}
            </IconButton>
          </Box>
          <Typography
            display={"flex"}
            justifyContent={"start"}
            fontSize={fonts.heading2}
            fontWeight={500}
            color={config?.card3Color}
            pb={1}
            borderBottom={3}
            borderRadius={"6px"}
            mt={2}
            textAlign={"left"}
            pl={2}
          >
            {config?.card3Count}
          </Typography>
        </Paper>
      )}
    </>
  );
}
