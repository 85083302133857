import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Pagination } from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import LandingPageSection from "../../components/dashboard/LandingPageSections";
import SearchAndFilters from "../../components/searchRow/SearchFilter";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GroupRemoveOutlinedIcon from "@mui/icons-material/GroupRemoveOutlined";
import BasicTable from "../../components/table/table";
import routes from "../../api/routes";
import useFetchSectionData from "../../hooks/useFetchSessionHook";
import useApiRequest from "../../api/api";
import { useNavigate } from "react-router";
import { formatDateToLongForm } from "../../components/utils/DateFormatter";
import { exportToCSV } from "../../components/utils/exportCSV";
import useDataFetch from "../../hooks/useDataFetch";
import LoadingBackdrop from "../../components/utils/loader";
import SmallLoader from "../../components/utils/small_loader";
const NewsLetter = () => {
  const [period, setPeriod] = useState("thisYear");
  const navigate = useNavigate();
  const config = {
    fromNewsletter: true, // or true based on your logic
    title: "",
    chartTitle: "Subscriptions",
    // card1Title: "Subscribed",
    // card1Count: "10 000 Users", // Example of user count
    card2Title: "Subscribers",
    card2Count: "10 000 Users", // Example of user count
    // card1Icon: <GroupOutlinedIcon sx={{ color: colors.teal600 }} />,
    card2Icon: <GroupRemoveOutlinedIcon sx={{ color: colors.purple }} />,
    // card1Color: colors.teal600,
    card2Color: colors.purple,
    // card1IconBgColor: colors.teal50,
    card2IconBgColor: "#EEE1FF",
    legend1: "Subscribers",
  };

  const columns = [
    { field: "id", label: "ID", style: { pr: 8 } },
    { field: "name", label: "Name" },
    { field: "email", label: "Email" },
    { field: "subscribed", label: "Subscribed" },
    { field: "joined", label: "Joined" },
    {
      field: "status",
      label: "Status",
      cellStyle: (value) => ({
        color: value === "REGISTERED" ? colors.green900 : colors.red900, // Using color codes for green900 and red900
      }),
    },
  ];

  const sections = [
    {
      endpoint: routes.newsletter.get_stats({ period }),
      config,
      key: "newsletter",
    },
  ];

  // const [data, setData] = useState([]); // Store fetched data
  const { data, updateSectionPeriod } = useFetchSectionData(sections, period);
  const {
    filteredData,
    totalPages,
    loading,
    pageNumber,
    handlePageChange,
    handleSearch,
    handleFilterChange,
    handleExportToExcel,
  } = useDataFetch(routes.newsletter, "name");
  const rows = filteredData?.newsletterMembers?.map((member) => ({
    id: member?.id.toString(), // Convert ID to string if needed
    name: member?.registeredUser?.username || "---",
    email: member?.email || member.registeredUser?.email || "---",
    subscribed: formatDateToLongForm(new Date(member?.createdOn)), // Format the date
    joined: formatDateToLongForm(new Date(member?.registeredUser?.createdOn)), // Format the date
    status: member?.registeredUser ? "REGISTERED" : "UNREGISTERED", // Determine status based on email
  }));
  const filters = [
    { label: "Name", value: "name" },
    { label: "Date Joined", value: "dateJoined" },
  ];
  const [loadingCount, setLoadingCount] = useState(0);
  useEffect(() => {
    if (loading) {
      setLoadingCount((prevCount) => prevCount + 1); // Increment counter on loading state change
    }
  }, [loading]); // Track changes to the loading state
  return (
    <Box sx={{ mx: "4%" }}>
      <Typography
        textAlign={"left"}
        fontSize={fonts.heading1}
        fontWeight={600}
        width={"250px"}
        mt={5}
        sx={{
          background: "linear-gradient(90deg, #74D0A9 0%, #9388D3 93.63%);",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
          textFillColor: "transparent",
        }}
      >
        Newsletter
        {loadingCount === 1 && <SmallLoader isLoading={loading} />}
      </Typography>
      <Typography
        mt={0.5}
        textAlign={"left"}
        gutterBottom
        color={colors.gray400}
      >
        See all the users that are making use of the web app
      </Typography>

      <Grid container spacing={3}>
        <LandingPageSection config={data?.newsletter} />
      </Grid>
      <Box mt={4}>
        <SearchAndFilters
          onSearch={handleSearch}
          onFilterChange={handleFilterChange}
          exportToExcel={handleExportToExcel}
          filters={filters}
        />
      </Box>
      <Box mt={4}>
        <BasicTable columns={columns} rows={rows} />
      </Box>
      <Box mb={6}>
        <Pagination
          count={totalPages} // Use dynamic totalPages from state
          page={pageNumber} // Control the current page
          onChange={handlePageChange} // Handle page changes
          color="primary"
          sx={{ mb: 0, justifyContent: "center", display: "flex" }}
        />
      </Box>
    </Box>
  );
};

export default NewsLetter;
