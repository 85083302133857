import React from "react";
import { Grid, Typography } from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import ActiveUsersChart from "./ActiveUsersChart";
import WorkoutsChart from "./WorkoutsChart";
import StatCard from "./StatCard";

const WebAppSection = ({
  configActiveUsersData,
  configWorkoutsData,
  configSupportData,
  onPeriodChange,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography
          textAlign={"left"}
          fontSize={fonts.heading2}
          color={colors.gray800}
          fontWeight={600}
          gutterBottom
        >
          Web App
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <ActiveUsersChart
          config={configActiveUsersData}
          onPeriodChange={(newPeriod) =>
            onPeriodChange("activeUsers", newPeriod)
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <WorkoutsChart
          config={configWorkoutsData}
          onPeriodChange={(newPeriod) => onPeriodChange("workouts", newPeriod)}
        />
      </Grid>

      <Grid item xs={12} md={4} sx={{ mb: 10, mt: 4 }}>
        <StatCard
          title="Workouts Created"
          value={`${configWorkoutsData?.totalWorkoutsCreated} Workouts`}
          icon="video"
          color={colors.teal600}
        />
        <StatCard
          title="Feedback Submitted"
          value={`${configSupportData?.totalSuggestions} Responses`}
          icon="feedback"
          color={colors.purple}
        />
      </Grid>
      <Grid item xs={12} md={4} sx={{ mt: 4 }}>
        <StatCard
          title="Workouts Played"
          value={`${configWorkoutsData?.totalWorkoutsPlayed} Plays`}
          icon="play"
          color={colors.teal600}
        />
        <StatCard
          title="Open Support Tickets"
          value={`${configSupportData?.totalOpenTickets} Tickets`}
          icon="support"
          color={colors.purple}
        />
      </Grid>
    </>
  );
};

export default WebAppSection;
