import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Pagination, Button } from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import LandingPageSection from "../../components/dashboard/LandingPageSections";
import SearchAndFilters from "../../components/searchRow/SearchFilter";
import BasicTable from "../../components/table/table";
import MuiPieChart from "../../components/dashboard/PieChart";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { formatDateToLongForm } from "../../components/utils/DateFormatter";
import routes from "../../api/routes";
import useFetchSectionData from "../../hooks/useFetchSessionHook";
import useDataFetch from "../../hooks/useDataFetch";
import LoadingBackdrop from "../../components/utils/loader";
import { hasPermission } from "../../components/utils/permissions/permissions";
import SmallLoader from "../../components/utils/small_loader";
const Exercises = () => {
  const navigate = useNavigate();
  const handleAddExercise = () => {
    navigate("/exercises/add");
  };
  const config = {
    fromExercise: true, // or true based on your logic
    title: "",
    chartTitle: "Top Used Exercises",
    card1Title: "Available Exercises",
    card1Count: "10 000 Exercises", // Example of user count
    card2Title: "Used Exercises",
    card2Count: "10 000 Exercises", // Example of user count
    card3Title: "Archived Exercises",
    card3Count: "10 000 Exercises", // Example of user count

    card1Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.teal600}
      >
        <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
      </svg>
    ),
    card2Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.purple}
      >
        <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
      </svg>
    ),
    card3Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.red900}
      >
        <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
      </svg>
    ),
    card1Color: colors.teal600,
    card2Color: colors.purple,
    card3Color: colors.red900,
    card1IconBgColor: colors.teal50,
    card2IconBgColor: "#EEE1FF",
    card3IconBgColor: colors.red100,
    legend1: "Open",
    legend2: "Closed",
  };
  const columns = [
    { field: "id", label: "ID", style: { pr: 8 } },
    { field: "name", label: "Name" },
    { field: "edited", label: "Edited" },
    { field: "added", label: "Added" },
    {
      field: "status",
      label: "Status",
      cellStyle: (value) => {
        let color;
        switch (value) {
          case "ACTIVE":
            color = colors.green900; // For ACTIVE status
            break;
          case "PROCESSING":
            color = colors.purple; // For PROCESSING status
            break;
          case "ARCHIVED":
            color = colors.red300; // For ARCHIVED status
            break;
          default:
            color = colors.gray600; // Default fallback color
        }
        return { color };
      },
    },
  ];

  const [period, setPeriod] = useState("thisYear");

  const sections = [
    {
      endpoint: routes.exercise.get_stats({ period }),
      config,
      key: "exercise",
    },
  ];

  // const [data, setData] = useState([]); // Store fetched data
  const { data, updateSectionPeriod } = useFetchSectionData(sections, period);
  const {
    filteredData,
    totalPages,
    loading,
    pageNumber,
    handlePageChange,
    handleSearch,
    handleFilterChange,
    handleExportToExcel,
  } = useDataFetch(routes.exercise, "relevance ", { all: true });

  const rows = React.useMemo(() => {
    if (
      !filteredData?.exercises ||
      !Array.isArray(filteredData.exercises) ||
      filteredData.exercises.length === 0
    ) {
      return [];
    }

    return filteredData.exercises.map((data) => ({
      id: data?.id ?? "N/A",
      name: data?.title ?? "---",
      edited: formatDateToLongForm(data?.updatedOn) ?? "N/A",
      added: formatDateToLongForm(data?.createdOn) ?? "N/A",
      status: data?.videoCdnUrl
        ? data?.deletedOn
          ? "ARCHIVED"
          : "ACTIVE"
        : "PROCESSING",
    }));
  }, [filteredData]);

  const filters = [
    { label: "Relevance", value: "relevance" },
    { label: "Date Created", value: "dateCreated" },
    { label: "Last Activity", value: "lastActivity" },
  ];
  const handleRowClick = (row) => {
    // navigate(`/users/details/${row.id}`);
    navigate(`/exercises/details?id=${row.id}`);
  };
  const [loadingCount, setLoadingCount] = useState(0);
  useEffect(() => {
    if (loading) {
      setLoadingCount((prevCount) => prevCount + 1); // Increment counter on loading state change
    }
  }, [loading]); // Track changes to the loading state
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const userRole = storedUser?.role;

  return (
    <Box sx={{ mx: "4%" }}>
      <Grid display="flex" justifyContent={"space-between"} mt={5}>
        <Box>
          <Typography
            textAlign={"left"}
            fontSize={fonts.heading1}
            fontWeight={600}
            width={"250px"}
            mt={0}
            sx={{
              background: "linear-gradient(90deg, #74D0A9 0%, #9388D3 93.63%);",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            Exercises
            {loadingCount === 1 && <SmallLoader isLoading={loading} />}
          </Typography>
          <Typography
            mt={0.5}
            textAlign={"left"}
            gutterBottom
            color={colors.gray400}
          >
            See all the users that are making use of the web app
          </Typography>
        </Box>
        {hasPermission(userRole, "UpdateExercises") && (
          <Button
            variant="contained"
            fontSize={fonts.heading4}
            size="large"
            endIcon={<Add />}
            sx={{
              marginLeft: 2,
              textTransform: "none",
              background: colors.teal400,
              fontWeight: 400,
              height: "40px",
              width: "150px",
              px: 2,
            }}
            onClick={handleAddExercise}
          >
            Add Exercise
          </Button>
        )}
      </Grid>
      <Grid container spacing={3}>
        <LandingPageSection config={data?.exercise} />
      </Grid>
      <Box mt={4}>
        <SearchAndFilters
          onSearch={handleSearch}
          onFilterChange={handleFilterChange}
          exportToExcel={handleExportToExcel}
          filters={filters}
        />
      </Box>
      <Box mt={4}>
        <BasicTable columns={columns} rows={rows} onRowClick={handleRowClick} />
      </Box>
      {rows.length > 0 && (
        <Box mb={6}>
          <Pagination
            count={totalPages} // Use dynamic totalPages from state
            page={pageNumber} // Control the current page
            onChange={handlePageChange} // Handle page changes
            color="primary"
            sx={{ mb: 0, justifyContent: "center", display: "flex" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Exercises;
