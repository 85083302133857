import React from "react";
import { Box, Typography, Paper, IconButton, Button } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import DividerLine from "../../components/utils/DividerLine";

const PromptSuggestionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
    fill={colors.teal50}
  >
    <path d="m600-200-56-57 143-143H300q-75 0-127.5-52.5T120-580q0-75 52.5-127.5T300-760h20v80h-20q-42 0-71 29t-29 71q0 42 29 71t71 29h387L544-624l56-56 240 240-240 240Z" />
  </svg>
);

export default function MessageSection({ messages, onReply }) {
  const storedUser = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          mt: 10,
        }}
      >
        <Typography
          sx={{
            fontSize: fonts.heading2,
            color: colors.gray800,
            fontWeight: 600,
          }}
        >
          Messages
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: colors.teal400,
            "&:hover": { backgroundColor: colors.teal500 },
            textTransform: "none",
            fontWeight: 400,
            px: 4,
          }}
          endIcon={<PromptSuggestionIcon />}
          onClick={onReply}
        >
          Reply
        </Button>
      </Box>
      <Box mt={-1}>
        <DividerLine />
      </Box>
      {messages?.map((message, index) => {
        const isCurrentUser = message?.senderId === parseInt(storedUser.id, 10);
        return (
          <Paper
            key={index}
            elevation={0}
            sx={{
              p: message.isAdmin ? 2 : 0,
              textAlign: message.isAdmin ? "right" : "left",
              border: "1px solid",
              borderColor: message.isAdmin ? colors.teal400 : colors.gray400,
              mb: index === messages.length - 1 ? 10 : 0,
              mt: 5,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                display: "block",
                textAlign: message.isAdmin ? "right" : "left",
                mb: 1,
                pt: message.isAdmin ? 0 : 2,
                pl: message.isAdmin ? 0 : 2,
                color: colors.gray500,
              }}
            >
              {message.isAdmin && isCurrentUser ? "You" : message.sender} at{" "}
              {message.timestamp}
            </Typography>
            <Typography
              sx={{ pl: message.isAdmin ? 0 : 2, pb: 2, color: colors.gray800 }}
            >
              {message.content}
            </Typography>
            {message.attachments && message.attachments.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                  mt: 4,
                  flexWrap: "wrap",
                }}
              >
                {message.attachments.map((imgSrc, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: "relative",
                      width: 100,
                      height: 100,
                      borderRadius: "4px",
                      border: "1px solid",
                      borderColor: colors.gray400,
                      overflow: "hidden",
                      "&:hover img": {
                        filter: "brightness(70%)",
                      },
                      "&:hover .iconOverlay": {
                        opacity: 1,
                      },
                    }}
                  >
                    <img
                      src={imgSrc}
                      alt={`Attachment ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        transition: "0.3s ease",
                      }}
                    />
                    <IconButton
                      className="iconOverlay"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "white",
                        opacity: 0,
                        transition: "opacity 0.3s ease",
                      }}
                      onClick={() => window.open(imgSrc, "_blank")}
                    >
                      <OpenInNewOutlinedIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}
          </Paper>
        );
      })}
    </>
  );
}
