import React from "react";
import { Box } from "@mui/material";
import AuthParentComponent from "../../components/signin/AuthComponent";
import useWindowWidth from "../../components/windowSize/WindowWidth";
const AuthSignPage = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 1200;

  return (
    <Box
      sx={{
        maxHeight: "100vh",
        position: "relative",
        overflow: "auto",

        "&::before": {
          content: '""',
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: `url("https://d3vdd33llamc8d.cloudfront.net/assets/auth/admin.png") no-repeat center center fixed`,
          backgroundSize: "cover",
          zIndex: -2,
        },
        "&::after": {
          content: '""',
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: "rgba(0, 77, 64, 0.5)", // Teal color with opacity
          backdropFilter: "blur(10px)",
          zIndex: -1,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          padding: "5rem 0",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "57%" : "41%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "3%",
            zIndex: 1,
          }}
        >
          <AuthParentComponent />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthSignPage;
