import { Typography } from "@mui/material";

// Define the custom DividerLine component
const DividerLine = () => {
  return (
    <Typography
      sx={{
        mt: 3,
        mb: 4,
        borderBottom: "1px solid",
        borderColor: "#9388D3",
        opacity: 0.5,
      }}
    />
  );
};

export default DividerLine;
