import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const BasicTable = ({ columns, rows, onRowClick }) => {
  return (
    <TableContainer elevation={0} sx={{ mb: 5 }} component={Paper}>
      <Table
        sx={{
          width: "100%",
          "& .MuiTableCell-root": {
            borderColor: "#bdbdbd",
            py: 3,
          },
        }}
        aria-label="dynamic table"
      >
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => (
              <TableCell
                key={index}
                align={column.align || "left"}
                sx={column.style}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length > 0 ? (
            rows.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                onClick={() => onRowClick && onRowClick(row)}
                sx={{ cursor: "pointer" }}
              >
                {columns?.map((column, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    align={column.align || "left"}
                    sx={{
                      ...column.style,
                      ...(typeof column.cellStyle === "function"
                        ? column.cellStyle(row[column.field], row)
                        : column.cellStyle),
                    }}
                  >
                    {row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns?.length || 1}
                align="center"
                sx={{ py: 3, fontStyle: "italic", color: "#757575" }}
              >
                No Record Available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
