import React from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  List,
  ListItem,
} from "@mui/material";
import colors from "../../styles/colors";

const DynamicRadioGroup = ({
  options,
  selectedValue,
  onChange,
  fonts,
  borderColor = colors.gray500,
  descriptionColor = colors.gray600,
  itemColor = colors.gray600,
  disabled = false,
}) => {
  return (
    <Box
      border="1px solid"
      borderColor={borderColor}
      borderRadius="8px"
      mt={3}
      sx={{ padding: 3, pl: 5 }}
      mb={4}
    >
      <RadioGroup value={selectedValue} onChange={onChange}>
        {Object.entries(options).map(([key, value]) => (
          <React.Fragment key={key}>
            <FormControlLabel
              value={key}
              control={<Radio disabled={disabled} />}
              label={value.label}
            />
            {selectedValue === key && (
              <>
                <Typography
                  fontSize={fonts.info}
                  color={descriptionColor}
                  textAlign="left"
                >
                  {value.description}
                </Typography>
                <List sx={{ mt: -1 }} dense>
                  {value.capabilities.map((item, index) => (
                    <ListItem key={index} sx={{ pl: 1 }}>
                      <Typography
                        fontSize={fonts.info}
                        color={itemColor}
                        component="span"
                        lineHeight={0.9}
                      >
                        • <Box component="span" mr={0.5}></Box>
                        {item}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </React.Fragment>
        ))}
      </RadioGroup>
    </Box>
  );
};

export default DynamicRadioGroup;
