import { useState, useEffect } from "react";
import useApiRequest from "../api/api";
import { exportToCSV } from "../components/utils/exportCSV";

const useDataFetch = (
  apiRoutes,
  defaultSortBy = "name",
  additionalParams = {}
) => {
  const { makeApiCall } = useApiRequest();

  const [filteredData, setFilteredData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  // Fetch Data Function
  const fetchData = async () => {
    setLoading(true);
    try {
      const queryParams = {
        pageNumber,
        sortBy: defaultSortBy,
        searchText,
        ...filters,
        ...additionalParams,
      };

      const url = apiRoutes.search(queryParams);
      const response = await makeApiCall(url, "GET");
      if (response && response.data) {
        setFilteredData(response.data); // Handle different API response structures
        setTotalPages(response.data.totalPages || 1);
      } else {
        console.error("Invalid response from API");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, searchText, filters]);

  // Handlers
  const handlePageChange = (_, newPage) => setPageNumber(newPage);
  const handleSearch = (query) => {
    setSearchText(query);
    setPageNumber(1); // Reset to first page
  };
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPageNumber(1); // Reset to first page
  };

  const handleExportToExcel = async () => {
    try {
      const response = await makeApiCall(apiRoutes.export, "GET");

      if (response && response.data) {
        const { excelData, filename } = response.data;
        if (excelData && excelData.length > 0) {
          exportToCSV(excelData, filename);
        } else {
          console.error("No data available for export.");
        }
      } else {
        console.error("Invalid response from API.");
      }
    } catch (error) {
      console.error("Failed to export data:", error);
    }
  };

  return {
    filteredData,
    totalPages,
    loading,
    pageNumber,
    fetchData,
    handlePageChange,
    handleSearch,
    handleFilterChange,
    handleExportToExcel,
  };
};

export default useDataFetch;
