import React from "react";
import { Paper, Box, Typography, Select, MenuItem } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
export const CustomLegend = ({ config }) => (
  <Box display="flex" justifyContent="center" mb={2}>
    <Box display="flex" alignItems="center" px={2} py={1} borderRadius={1}>
      <Box
        mr={1}
        bgcolor={config?.legend1Color || config?.card2Color}
        width={"20px"}
        height={"20px"}
      ></Box>
      <Typography color="black" fontSize={16}>
        {config?.legend1 ? config?.legend1 : config?.card2Title}
      </Typography>
    </Box>
    {config?.legend2 && (
      <Box display="flex" alignItems="center" px={2} py={1} borderRadius={1}>
        <Box
          mr={1}
          bgcolor={config?.legend2Color || config?.card1Color}
          width={"20px"}
          height={"20px"}
        ></Box>
        <Typography color="black" fontSize={16}>
          {config?.legend2 ? config?.legend2 : config?.card1Title}
        </Typography>
      </Box>
    )}
    {config?.legend3 && (
      <Box display="flex" alignItems="center" px={2} py={1} borderRadius={1}>
        <Box
          mr={1}
          bgcolor={config?.legend3Color}
          width={"20px"}
          height={"20px"}
        ></Box>
        <Typography color="black" fontSize={16}>
          {config?.legend3}
        </Typography>
      </Box>
    )}
    {config?.legend4 && (
      <Box display="flex" alignItems="center" px={2} py={1} borderRadius={1}>
        <Box
          mr={1}
          bgcolor={config?.legend4Color}
          width={"20px"}
          height={"20px"}
        ></Box>
        <Typography color="black" fontSize={16}>
          {config?.legend4}
        </Typography>
      </Box>
    )}
  </Box>
);
const VisitorsChart = ({ config, onPeriodChange }) => {
  const isFromSuggestion = config?.fromSuggestions ? true : false;
  const handleSelectChange = (event) => {
    const selectedPeriod = event.target.value;
    onPeriodChange(selectedPeriod); // Trigger the callback to update the main page
  };
  const visitorData = config?.graphData
    ? config?.graphData.legendOneDataPoints.map((point, index) => {
        const dataPoint = {
          name: config?.graphData.xLabels[index],
          [config?.legend1]: point, // Set the first legend data point
        };

        // Check for additional legends and add them dynamically
        if (config?.graphData.legendTwoDataPoints) {
          dataPoint[config?.legend2] =
            config?.graphData.legendTwoDataPoints[index];
        }
        if (config?.graphData.legendThreeDataPoints) {
          dataPoint[config?.legend3] =
            config?.graphData.legendThreeDataPoints[index];
        }
        if (config?.graphData.legendFourDataPoints) {
          dataPoint[config?.legend4] =
            config?.graphData.legendFourDataPoints[index];
        }

        return dataPoint;
      })
    : [];

  const legendKeys = [
    config?.legend1,
    config?.legend2,
    config?.legend3,
    config?.legend4,
  ].filter(Boolean);
  return (
    <Paper
      elevation={0}
      sx={{
        width: "98%",
        boxShadow: "0px 4px 20px 0px #00000014",
        pt: 5,
        pb: 2,
        pr: 4,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        mt={-3}
        borderRadius={"8px"}
      >
        <Typography
          color={colors.gray800}
          fontSize={fonts.heading3}
          fontWeight={600}
          ml={2}
        >
          {config?.chartTitle}
        </Typography>
        {config?.fromDashboard && (
          <Select
            defaultValue="today"
            sx={{
              pr: 1.5,
              fontSize: fonts.heading4,
              color: "rgba(0, 0, 0, 0.38)",
            }}
            size="small"
            onChange={handleSelectChange}
          >
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="thisMonth">This Month</MenuItem>
            <MenuItem value="thisYear">This Year</MenuItem>
          </Select>
        )}
      </Box>
      <CustomLegend config={config} />

      <ResponsiveContainer width="100%" height={208}>
        <LineChart data={visitorData}>
          <CartesianGrid style={{ opacity: 0 }} strokeDasharray="3" />
          <XAxis
            style={{ opacity: 0.5 }}
            fontSize={12}
            tickMargin={10}
            interval={0}
            dataKey="name"
            textAnchor="end" // Align text after rotation
          />
          <YAxis style={{ opacity: 0.5 }} fontSize={12} tickMargin={8} />
          <Tooltip />
          {legendKeys?.map((legend, index) => (
            <Line
              key={index}
              type="linear"
              dataKey={legend}
              strokeWidth={2}
              stroke={
                index === 0
                  ? config?.legend1Color || config?.card2Color
                  : index === 1
                  ? config?.legend2Color || config?.card1Color
                  : index === 2
                  ? config?.legend3Color
                  : config?.legend4Color
              }
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default VisitorsChart;
