// import "../App.css";
// import React, { useEffect } from "react";
// import { useLocation, Routes, Route } from "react-router-dom";
// import AuthSignPage from "../pages/auth/AuthPage";
// import MiniDrawer from "../components/sidebar/sidebar";
// import Dashboard from "../pages/dashboard/MainDashboard";
// import Users from "../pages/users/users";
// import NewsLetter from "../pages/newsletter/Newsletter";
// import Support from "../pages/support/Support_Ticket";
// import Suggestions from "../pages/suggestion/Suggestions";
// import Exercises from "../pages/exercise/Exercises";
// import AddExercise from "../pages/exercise/AddExercise";
// import TicketDetails from "../pages/support/Ticket_Details";
// import FeedbackDetails from "../pages/suggestion/FeedbackDetail";
// import UserDetails from "../pages/users/UserDetail";
// import Admin from "../pages/admin/Admin";
// import AddAdmin from "../pages/admin/AddAdmin";
// function Layout() {
//   const location = useLocation();
//   // const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [location]);
//   const noMiniDrawerRoutes = ["/", "/forgot-password", "/404", "/5xx"];

//   return (
//     <div className="App" style={{ display: "flex" }}>
//       {!noMiniDrawerRoutes.includes(location.pathname) && <MiniDrawer />}

//       <div className="App-content" style={{ flexGrow: 1, marginLeft: "60px" }}>
//         <Routes location={location} key={location.pathname}>
//           <Route path="/" element={<AuthSignPage />} />
//           <Route path="/dashboard" element={<Dashboard />} />
//           <Route path="/users" element={<Users />} />
//           <Route path="/users/details" element={<UserDetails />} />
//           <Route path="/newsletter" element={<NewsLetter />} />
//           <Route path="/support" element={<Support />} />
//           <Route path="/feedback" element={<Suggestions />} />
//           <Route path="/feedback/details" element={<FeedbackDetails />} />
//           <Route path="/exercises" element={<Exercises />} />
//           <Route path="/exercises/add" element={<AddExercise />} />
//           <Route path="/exercises/details" element={<AddExercise />} />
//           <Route path="/support/details" element={<TicketDetails />} />
//           <Route path="/admin" element={<Admin />} />
//           <Route path="/admin/add" element={<AddAdmin />} />
//           <Route path="/admin/details" element={<AddAdmin />} />
//         </Routes>
//       </div>
//     </div>
//   );
// }

// export default Layout;

import "../App.css";
import React, { useEffect } from "react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import AuthSignPage from "../pages/auth/AuthPage";
import MiniDrawer from "../components/sidebar/sidebar";
import Dashboard from "../pages/dashboard/MainDashboard";
import Users from "../pages/users/users";
import NewsLetter from "../pages/newsletter/Newsletter";
import Support from "../pages/support/Support_Ticket";
import Suggestions from "../pages/suggestion/Suggestions";
import Exercises from "../pages/exercise/Exercises";
import AddExercise from "../pages/exercise/AddExercise";
import TicketDetails from "../pages/support/Ticket_Details";
import FeedbackDetails from "../pages/suggestion/FeedbackDetail";
import UserDetails from "../pages/users/UserDetail";
import Admin from "../pages/admin/Admin";
import AddAdmin from "../pages/admin/AddAdmin";
import ProtectedRoute from "./protectedRoutes";
import { getDashboardByRole } from "../components/utils/permissions/permissions";

function Layout() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const noMiniDrawerRoutes = ["/", "/forgot-password", "/404", "/5xx"];
  const AuthRedirect = () => {
    const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
    if (storedUser && storedUser.token) {
      const userRole = storedUser.role;
      const dashboardRoute = getDashboardByRole(userRole);
      return <Navigate to={dashboardRoute} replace />;
    }
    return <AuthSignPage />;
  };
  return (
    <div className="App" style={{ display: "flex" }}>
      {!noMiniDrawerRoutes.includes(location.pathname) && <MiniDrawer />}

      <div className="App-content" style={{ flexGrow: 1, marginLeft: "60px" }}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<AuthRedirect />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute
                element={Dashboard}
                requiredPermission="ViewDashboard"
              />
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute element={Users} requiredPermission="ViewUsers" />
            }
          />
          <Route
            path="/users/details"
            element={
              <ProtectedRoute
                element={UserDetails}
                requiredPermission="ViewUsers"
              />
            }
          />
          <Route
            path="/newsletter"
            element={
              <ProtectedRoute
                element={NewsLetter}
                requiredPermission="ViewNewsletters"
              />
            }
          />
          <Route
            path="/support"
            element={
              <ProtectedRoute
                element={Support}
                requiredPermission="ViewSupportTickets"
              />
            }
          />
          <Route
            path="/feedback"
            element={
              <ProtectedRoute
                element={Suggestions}
                requiredPermission="ViewFeedbacks"
              />
            }
          />
          <Route
            path="/feedback/details"
            element={
              <ProtectedRoute
                element={FeedbackDetails}
                requiredPermission="ViewFeedbacks"
              />
            }
          />
          <Route
            path="/exercises"
            element={
              <ProtectedRoute
                element={Exercises}
                requiredPermission="ViewExercises"
              />
            }
          />
          <Route
            path="/exercises/add"
            element={
              <ProtectedRoute
                element={AddExercise}
                requiredPermission="UpdateExercises"
              />
            }
          />
          <Route
            path="/exercises/details"
            element={
              <ProtectedRoute
                element={AddExercise}
                requiredPermission="ViewExercises"
              />
            }
          />
          <Route
            path="/support/details"
            element={
              <ProtectedRoute
                element={TicketDetails}
                requiredPermission="ViewSupportTickets"
              />
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute element={Admin} requiredPermission="ViewInfo" />
            }
          />
          <Route
            path="/admin/add"
            element={
              <ProtectedRoute
                element={AddAdmin}
                requiredPermission="UpdateAdmins"
              />
            }
          />
          <Route
            path="/admin/details"
            element={
              <ProtectedRoute
                element={AddAdmin}
                requiredPermission="ViewAdmins"
              />
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
  );
}

export default Layout;
