import React from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import colors from "../../styles/colors";
import useWindowWidth from "../windowSize/WindowWidth";
import fonts from "../../styles/fontVars";
import WestSharpIcon from "@mui/icons-material/WestSharp";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";
const NewPassword = ({ handleBack, handleContinue }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showPassword2, setShowPassword2] = React.useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  // const handleSave = () => {
  //   navigate("/dashboard");
  //   // Example: navigate(-1); if using react-router
  // };
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  return (
    <Box sx={{ width: isMobile ? "100%" : "100%" }}>
      <Typography
        sx={{
          color: colors.gray600,
          display: "flex",
          justifyContent: isMobile ? "center" : "left",
          textAlign: "left",
          mt: 0,
          cursor: "pointer",
        }}
        // variant={windowWidth <= 768 ? "h5" : "h4"}
        fontSize={fonts.body}
        gutterBottom
        onClick={handleBack}
      >
        <WestSharpIcon /> <Typography sx={{ marginLeft: 1 }}>Back</Typography>{" "}
      </Typography>
      <Typography
        sx={{
          color: colors.gray900,
          display: "flex",
          justifyContent: isMobile ? "center" : "left",
          textAlign: "left",
          mt: 5,
        }}
        // variant={windowWidth <= 768 ? "h5" : "h4"}
        fontWeight="bold"
        fontSize={fonts.heading1}
        gutterBottom
      >
        Forgot Password?
      </Typography>
      <Typography
        sx={{
          ml: isMobile ? 1 : 0,
          display: "flex",
          justifyContent: "left",
          textAlign: "left",
          width: "280px",
          fontSize: fonts.heading2,
          fontWeight: 600,
          color: colors.gray900,
          mt: 7,
        }}
        // variant={isMobile ? "body2" : fonts.body}

        gutterBottom
      >
        Create A New Password
      </Typography>

      <TextField
        fullWidth
        label="New Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type={showPassword ? "text" : "password"}
        margin="normal"
        sx={{ ml: isMobile ? 0.6 : 0, mt: 4 }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
      />
      <TextField
        fullWidth
        label="Confirm Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        type={showPassword ? "text" : "password"}
        margin="normal"
        sx={{ ml: isMobile ? 0.6 : 0 }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword2}
                >
                  {showPassword2 ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
      />

      <Button
        fullWidth
        variant="contained"
        onClick={() => handleContinue(password, confirmPassword)}
        sx={{
          mt: 5,
          py: 1,
          background: colors.teal400,
          height: isMobile ? "45px" : "60px",
          borderRadius: "4px",
          textTransform: "none",
          fontSize: isMobile ? 12 : fonts.heading4,
          letterSpacing: 1,
          fontWeight: 400,
          transition: "box-shadow 0.3s ease",
          "&:hover": {
            boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.4)", // Drop shadow effect
          },
        }}
      >
        Save
      </Button>
    </Box>
  );
};

export default NewPassword;
