import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Layout from "./routes/layout";
import "./App.css";
import colors from "./styles/colors";
import { SnackbarProvider } from "./components/utils/SnackbarProvider";

// Define your custom theme
const theme = createTheme({
  typography: {
    fontFamily: "Rubik",
  },
  palette: {
    primary: {
      main: colors.teal400,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.teal400, // Global focus color for the outline border
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: colors.teal400, // Global focus color for the label text
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: colors.teal400, // Default color
          "&.Mui-checked": {
            color: colors.teal400, // Color when checked
          },
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: colors.teal900, // Track color when checked
          },
        },
        track: {
          backgroundColor: colors.gray800, // Track color by default
        },
      },
    },
  },
});

function App() {
  return (
    // Wrap your app with ThemeProvider and pass the custom theme
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <Router>
          <Layout />
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
