import React from "react";
import { Navigate } from "react-router-dom";
import {
  getDashboardByRole,
  hasPermission,
} from "../components/utils/permissions/permissions";
import { useSnackbar } from "../components/utils/SnackbarProvider";

const ProtectedRoute = ({ element: Element, requiredPermission }) => {
  const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
  const userRole = storedUser.role;
  const dashboardRoute = getDashboardByRole(userRole);
  const showSnackbar = useSnackbar(); // Use the global Snackbar

  if (!userRole || !hasPermission(userRole, requiredPermission)) {
    showSnackbar("You are unauthorized to access this page", "error");

    // Redirect to dashboard or a "Not Authorized" page
    return <Navigate to={dashboardRoute} replace />;
  }

  return <Element />;
};

export default ProtectedRoute;
