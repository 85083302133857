import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Breadcrumbs,
  Link,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  FormControlLabel,
  RadioGroup,
  ListItem,
  Radio,
  List,
  Dialog,
  DialogContent,
} from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ConfirmationDialog from "../../components/utils/Dialog";
import { useNavigate } from "react-router";
import DynamicRadioGroup from "../../components/utils/RadioButtons";
import useApiRequest from "../../api/api";
import { useSearchParams } from "react-router-dom";
import routes from "../../api/routes";
import Otp from "../../components/forgotpw/Otp";
import LoadingBackdrop from "../../components/utils/loader";

const AddAdmin = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState("");
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [verifyNow, setVerifyNow] = useState(false);
  const { makeApiCall } = useApiRequest();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleCancel = () => {
    setOpenDialog(false);
    setOpenDeleteDialog(false);
    setVerifyNow(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await makeApiCall(
        routes.super_admin_options.get(id),
        "GET"
      );
      const adminData = response.data.admin;
      setData(adminData);
      setSelectedPermission(adminData.role);
      setUsername(adminData.username);
      setEmail(adminData.email);
    } catch (e) {
      navigate("/admin");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handlePassword = () => {
    setShowPassword(!showPassword);
  };
  const [selectedPermission, setSelectedPermission] = useState("");

  const permissionDetails = {
    admin: {
      label: "Super Administrator",
      description: "Users with this permission can",
      capabilities: [
        "Create new admins with any permissions",
        "Alter existing admins",
        "View and alter exercise data",
        "View and alter user data",
        "View and alter customer support messages",
      ],
    },
    read: {
      label: "Read Only",
      description: "Users with this permission can",
      capabilities: [
        "View all data without editing capabilities",
        "Access reports and analytics",
      ],
    },
    inventory: {
      label: "Exercise Inventory",
      description: "Users with this permission can",
      capabilities: [
        "Manage exercise database",
        "Add, edit, or remove exercises",
        "Categorize and tag exercises",
      ],
    },
    support: {
      label: "Customer Support",
      description: "Users with this permission can",
      capabilities: [
        "Access customer inquiries and tickets",
        "Respond to customer messages",
        "View user account details for support purposes",
      ],
    },
  };

  const handlePermissionChange = (event) => {
    setSelectedPermission(event.target.value);
  };
  const handleConfirm = async () => {
    // Handle the actual logout logic here (e.g., clearing user session, tokens, etc.)
    setIsLoading(true);
    try {
      const response = await makeApiCall(
        routes.super_admin_options.delete(id),
        "DELETE"
      );
      if (response.status) {
        navigate("/admin");
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyEmail = async (otp) => {
    const payload = {
      email: email,
      code: otp,
      rememberMeForSevenDays: false,
    };
    const response = await makeApiCall(
      routes.account.auth.verify,
      "POST",
      payload,
      "show"
    );
    if (response.status) {
      navigate("/admin");
    }
  };

  const handleVerify = async (sendVerification) => {
    const payload = {
      username: username,
      email: email,
      password: password,
      confirmPassword: password,
      role: selectedPermission,
      sendVerification: sendVerification,
    };
    const response = await makeApiCall(
      routes.account.auth.register,
      "POST",
      payload,
      sendVerification ? "show" : "hide"
    );
    if (response.status) {
      if (sendVerification) {
        setVerifyNow(true);
      } else {
        navigate("/admin");
      }
    }
    // setVerifyNow(true);

    setOpenDialog(false);
  };
  const handleResend = async () => {
    const payload = {
      email: email,
    };
    const response = await makeApiCall(
      routes.account.auth.send_code,
      "POST",
      payload,
      "show"
    );
  };
  return (
    <Box sx={{ mx: "4%" }}>
      <LoadingBackdrop isLoading={isLoading} />

      <Grid display="flex" justifyContent={"space-between"} mt={5}>
        <Box>
          <Typography
            textAlign={"left"}
            fontSize={fonts.heading1}
            fontWeight={600}
            width={"300px"}
            mt={0}
            sx={{
              background: "linear-gradient(90deg, #74D0A9 0%, #9388D3 93.63%);",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            Administrators
          </Typography>
          <Typography
            mt={0.5}
            textAlign={"left"}
            gutterBottom
            color={colors.gray400}
          >
            Quickly look through the most important visually represented
            insights
          </Typography>
        </Box>
      </Grid>
      <Box display="flex" justifyContent={"space-between"} mt={3}>
        <Breadcrumbs>
          <Link
            color={colors.gray400}
            sx={{ textDecoration: "none" }}
            href="/admin"
          >
            All Admins
          </Link>

          <Typography color="text.secondary">
            {data?.username || "New Admin"}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box sx={{ width: "620px" }}>
        <form autoComplete="off">
          <TextField
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="off"
            disabled={data}
            sx={{
              mb: 2,
              mt: 6,
              width: "80%",
              display: "flex",
              justifyContent: "start",
            }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleOutlinedIcon />
                  </InputAdornment>
                ),
              },
            }}
          />

          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              mb: 4,
              mt: 4,
              width: "80%",
              display: "flex",
              justifyContent: "start",
            }}
            disabled={data}
            slotProps={{
              input: {
                autoComplete: "off",
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailOutlinedIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
        </form>
        {!data && (
          <>
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              sx={{
                mt: 4,
                width: "80%",
                display: "flex",
                justifyContent: "start",
              }}
              disabled={data}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handlePassword}
                      >
                        {showPassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />

            <TextField
              sx={{
                mt: 3,
                width: "80%",
                display: "flex",
                justifyContent: "start",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={data}
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              margin="normal"
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
          </>
        )}
      </Box>
      <Typography
        textAlign={"left"}
        fontSize={fonts.heading2}
        color={colors.gray800}
        fontWeight={600}
        mt={7}
      >
        Change Permissions
      </Typography>
      <DynamicRadioGroup
        options={permissionDetails}
        selectedValue={selectedPermission}
        onChange={handlePermissionChange}
        colors={colors}
        fonts={fonts}
      />

      <Grid item xs={12} mt={7} md={12}>
        {!data && (
          <Button
            variant="contained"
            sx={{
              display: "flex",
              justifyContent: "left",
              mb: 8,
              textTransform: "none",
              fontSize: fonts.heading4,
              background: colors.red900,
              color: colors.red100,
              px: 5,
            }}
            disabled={
              !(
                username &&
                email &&
                password &&
                confirmPassword &&
                selectedPermission
              )
            }
            onClick={handleOpenDialog}
          >
            Create an Administrator
          </Button>
        )}
        {data && (
          <Button
            variant="contained"
            sx={{
              display: "flex",
              justifyContent: "left",
              mb: 8,
              textTransform: "none",
              fontSize: fonts.heading4,
              background: colors.red900,
              color: colors.red100,
              px: 5,
            }}
            onClick={handleOpenDeleteDialog}
          >
            Close this Account
          </Button>
        )}
      </Grid>

      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancel}
        onConfirm={(verify) => handleVerify(verify)}
        title="Verification Required"
        content="Before granting this user access we must verify their email. You can choose to verify right now or when the user uses these credentials to login"
        cancelButtonText="Later"
        confirmButtonText="Verify Now"
        cancelButtonProps={{ sx: { color: colors.gray500 } }}
        confirmButtonProps={{ sx: { color: colors.teal400 } }}
        disableOnClose={true}
      />
      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title="Delete"
        content="Are you sure you want to delete this account? This action is irreversible"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        cancelButtonProps={{ sx: { color: colors.gray500 } }}
        confirmButtonProps={{ sx: { color: colors.red900 } }}
      />
      {verifyNow && (
        <Dialog open={verifyNow} fullWidth maxWidth="sm">
          <DialogContent>
            <Otp
              isFromRegister={true}
              isFromAdmin={true}
              handleBack={handleCancel}
              handleContinue={handleVerifyEmail}
              handleResend={handleResend}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default AddAdmin;
