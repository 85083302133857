export const desktopOS = [
  {
    label: "Exercise 5",
    value: 72.72,
  },
];

export const mobileOS = [
  {
    label: "Exercise 4",
    value: 70.48,
  },
];
export const iosIos = [
  {
    label: "Exercise 6",
    value: 70.48,
  },
];

export const platforms = [
  {
    label: "Exercise 1",
    value: 59.12,
  },
  {
    label: "Exercise 2",
    value: 40.88,
  },
  {
    label: "Exercise 3",
    value: 40.88,
  },
];

const normalize = (v, v2) => Number.parseFloat(((v * v2) / 100).toFixed(2));

export const mobileAndDesktopOS = [
  ...mobileOS.map((v) => ({
    ...v,
    label: v.label === "Other" ? "Other (Mobile)" : v.label,
    value: normalize(v.value, platforms[0].value),
  })),
  ...desktopOS.map((v) => ({
    ...v,
    label: v.label === "Other" ? "Other (Desktop)" : v.label,
    value: normalize(v.value, platforms[1].value),
  })),
  ...iosIos.map((v) => ({
    ...v,
    label: v.label === "Other" ? "Other (androidOS)" : v.label,
    value: normalize(v.value, platforms[2].value),
  })),
];

export const valueFormatter = (item) => `${item.value}%`;
