import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
} from "@mui/material";

import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import colors from "../../styles/colors";
import useWindowWidth from "../windowSize/WindowWidth";
import fonts from "../../styles/fontVars";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";
import ForgotPw from "../forgotpw/ForgotPassword";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
import { useSnackbar } from "../utils/SnackbarProvider";
import LoadingBackdrop from "../utils/loader";
import Otp from "../forgotpw/Otp";
import { getDashboardByRole } from "../utils/permissions/permissions";
// import ForgotPw from "../forgotpw/ForgotPassword";
const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { makeApiCall } = useApiRequest();
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [showForgotPw, setShowForgotPw] = useState(false);

  const handleNavigateForgotPw = () => {
    setShowForgotPw(true); // Set the state to true to render the Otp component
  };
  const [verifyNow, setVerifyNow] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [rememberMeForSevenDays, setRememberMeForSevenDays] = useState(false);

  const handleCheckboxChange = (event) => {
    setRememberMeForSevenDays(event.target.checked);
  };
  const handleBack = () => {
    setShowForgotPw(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    // if (!email) {
    //   showSnackbar("Email is required", "error");
    //   return;
    // }
    // if (!password) {
    //   showSnackbar("Password is required", "error");
    //   return;
    // }
    // if (password.length < 8) {
    //   showSnackbar("Password must be at least 8 characters long", "error");
    //   return;
    // }

    // API Call to sign in
    const payload = {
      email: email,
      password: password,
      rememberMeForSevenDays: rememberMeForSevenDays,
    };

    setIsLoading(true);
    const response = await makeApiCall(
      routes.account.auth.login,
      "POST",
      payload
      // "show"
    );

    if (response.status) {
      if (response.data.toVerification) {
        // navigate("/register", { state: { verify: true, email: email } });
        setVerifyNow(true);
        showSnackbar(response.message, "info");
      } else {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        const dashboardRoute = getDashboardByRole(response.data.user.role);
        navigate(dashboardRoute);
      }
    } else {
      showSnackbar(response.message, "error");
    }

    setIsLoading(false);
  };
  const handleCancel = () => {
    setVerifyNow(false);
  };
  const handleVerifyEmail = async (otp, rememberMeForSevenDays) => {
    const payload = {
      email: email,
      code: otp,
      rememberMeForSevenDays: rememberMeForSevenDays,
    };
    const response = await makeApiCall(
      routes.account.auth.verify,
      "POST",
      payload
    );
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (response.status) {
      localStorage.setItem("user", JSON.stringify(response.data.user));
      const dashboardRoute = getDashboardByRole(response.data.user.role);
      navigate(dashboardRoute);
    }
  };
  const handleResend = async () => {
    const payload = {
      email: email,
    };
    const response = await makeApiCall(
      routes.account.auth.send_code,
      "POST",
      payload,
      "show"
    );
  };
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  return (
    <Box sx={{ width: isMobile ? "100%" : "80%" }}>
      <LoadingBackdrop isLoading={isLoading} />

      {!showForgotPw ? (
        <>
          <Typography
            sx={{
              color: colors.gray900,
              display: "flex",
              justifyContent: isMobile ? "center" : "left",
              textAlign: "left",
            }}
            // variant={windowWidth <= 768 ? "h5" : "h4"}
            fontWeight="bold"
            fontSize={fonts.heading1}
            gutterBottom
          >
            Admin
          </Typography>
          <Typography
            sx={{
              color: colors.gray900,
              display: "flex",
              justifyContent: isMobile ? "center" : "left",
              textAlign: "left",
              mt: 4,
            }}
            // variant={windowWidth <= 768 ? "h5" : "h4"}
            fontWeight="bold"
            fontSize={fonts.heading2}
            gutterBottom
          >
            Login to your Account
          </Typography>

          <TextField
            fullWidth
            label="Enter your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            sx={{ ml: isMobile ? 0.6 : 0, mt: 3 }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailOutlinedIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
          <TextField
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            sx={{ ml: isMobile ? 0.6 : 0 }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />

          <Typography
            component="span"
            display={"flex"}
            justifyContent={"right"}
            sx={{
              fontSize: isMobile ? "11px" : "16px",
              ml: isMobile ? 2 : 0,
            }}
          >
            <Typography
              onClick={handleNavigateForgotPw}
              sx={{ cursor: "pointer", textDecoration: "underline" }}
              color="#00796B"
            >
              Forgot Password?
            </Typography>
          </Typography>
          <FormControlLabel
            control={
              isMobile ? (
                <Box sx={{ width: 0, height: 0 }} />
              ) : (
                <Checkbox
                  name="remember"
                  checked={rememberMeForSevenDays}
                  onChange={handleCheckboxChange}
                />
              )
            }
            sx={{
              display: "flex",
              justifyContent: "left",
              textAlign: "left",
              fontSize: isMobile ? "18px" : "24px",
            }}
            label={
              <Typography
                component="span"
                sx={{
                  fontSize: isMobile ? "11px" : "16px",
                  ml: isMobile ? 2 : 0,
                }}
              >
                Remember me for 7 days.
              </Typography>
            }
          />
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            sx={{
              mt: 4,
              py: 1,
              background: "linear-gradient(to right, #74D0A9, #9388D3 )",
              height: isMobile ? "45px" : "60px",
              mb: 6,
              borderRadius: "4px",
              textTransform: "none",
              fontSize: isMobile ? 12 : fonts.heading4,
              letterSpacing: 1,
              fontWeight: 400,
              transition: "box-shadow 0.3s ease",
              "&:hover": {
                boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.4)", // Drop shadow effect
              },
            }}
          >
            Login
          </Button>
          {verifyNow && (
            <Dialog open={verifyNow} fullWidth maxWidth="sm">
              <DialogContent>
                <Otp
                  isFromRegister={true}
                  handleBack={handleCancel}
                  handleContinue={handleVerifyEmail}
                  handleResend={handleResend}
                />
              </DialogContent>
            </Dialog>
          )}
        </>
      ) : (
        // <></>
        <Box mb={4}>
          <ForgotPw handleBack={handleBack} />
        </Box>
      )}
    </Box>
  );
};

export default SignInForm;
