import React, { useRef, useState } from "react";
import { FilterList, SaveAlt, Search } from "@mui/icons-material";
import {
  Box,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import fonts from "../../styles/fontVars";
import colors from "../../styles/colors";

const SearchAndFilters = ({
  onSearch,
  onFilterChange,
  exportToExcel,
  filters,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const filterRef = useRef(null); // Create a ref for the filter element

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchSubmit = () => {
    onSearch(searchValue); // Trigger search when the user submits
  };

  const handleFilterSelection = (filter) => {
    onFilterChange({ sortBy: filter }); // Pass the filter value to the parent
    setAnchorEl(null); // Close the menu
  };

  const handleClick = (event) => {
    setAnchorEl(filterRef.current); // Set the anchor element to the ref
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <TextField
        label="Search"
        sx={{
          display: "flex",
          width: {
            lg: "35%",
            sm: "60%",
          },
        }}
        onChange={handleSearchInputChange}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <Search
                  sx={{ cursor: "pointer" }}
                  onClick={handleSearchSubmit}
                />
              </InputAdornment>
            ),
          },
        }}
      />
      <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
        <Box
          ref={filterRef} // Attach the ref to the Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <FilterList
            onClick={handleClick}
            sx={{ mr: 1, color: colors.teal400 }}
          />
          <Typography
            onClick={handleClick}
            fontSize={fonts.info}
            fontWeight={500}
            sx={{
              color: "#26a69a", // Replace with your colors.teal400
              "&:hover": { color: "#26a69a" },
              transition: "color 0.3s",
              cursor: "pointer",
            }}
          >
            FILTERS
          </Typography>

          {/* Menu that opens when FILTERS is clicked */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              elevation: 3,
            }}
          >
            {filters?.map((filter) => (
              <MenuItem
                key={filter.value}
                onClick={() => handleFilterSelection(filter.value)}
              >
                {filter.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box
          onClick={exportToExcel}
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <SaveAlt sx={{ mr: 1, color: colors.teal400 }} />
          <Typography
            fontSize={fonts.info}
            fontWeight={500}
            sx={{
              color: colors.teal400,
              "&:hover": { color: colors.teal400 },
              transition: "color 0.3s",
            }}
          >
            EXPORT TO EXCEL
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default SearchAndFilters;
