import React from "react";
import { Grid, Typography } from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import VisitorsChart from "./VisitorsChart";
import NewsletterSubscriptions from "./NewsletterSubscriptions";
import SocialRedirect from "./SocialRedirect";
import MuiPieChart from "./PieChart";
const LandingPageSection = ({ config, onPeriodChange }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography
          textAlign={"left"}
          fontSize={fonts.heading2}
          fontWeight={600}
          color={colors.gray800}
          gutterBottom
          mt={3}
        >
          {config?.title ? config?.title : ""}
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        lg={config?.fromExercise ? 6 : 8}
        md={config?.fromExercise ? 6 : 12}
      >
        {config?.fromExercise ? (
          <MuiPieChart config={config} />
        ) : (
          <VisitorsChart config={config} onPeriodChange={onPeriodChange} />
        )}
      </Grid>

      <Grid
        item
        xs={12}
        lg={config?.fromExercise ? 6 : 4}
        md={config?.fromExercise ? 6 : 12}
        mb={2}
      >
        {config?.card1Title && <NewsletterSubscriptions config={config} />}
        <SocialRedirect config={config} />
      </Grid>
    </>
  );
};

export default LandingPageSection;
