import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Drawer,
  Grid,
} from "@mui/material";

import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DividerLine from "./DividerLine";
import { Add } from "@mui/icons-material";
import FileUploader from "./FileUploader";
import routes from "../../api/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import useApiRequest from "../../api/api";
import LoadingBackdrop from "./loader";
const sectionsData = [
  {
    id: "section-1",
    exerciseName: "Push-Ups",
    description:
      "A basic upper body exercise to strengthen the chest and arms.",
    exerciseDuration: "00:45", // Format: Minutes:Seconds
    restDuration: "00:15", // Format: Minutes:Seconds
  },
  {
    id: "section-2",
    exerciseName: "Squats",
    description: "A lower body exercise that strengthens the legs and glutes.",
    exerciseDuration: "01:00",
    restDuration: "00:30",
  },
  {
    id: "section-3",
    exerciseName: "Plank",
    description:
      "An isometric core exercise to build endurance in your abs and back.",
    exerciseDuration: "00:30",
    restDuration: "00:10",
  },
];
const ReplyMessageModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { makeApiCall } = useApiRequest();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [sections, setSections] = useState(sectionsData);
  const [messageText, setMessageText] = useState("");
  const [messageMedia, setMessageMedia] = useState([]); // Array to hold selected images
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = (files) => {
    setMessageMedia(files);
  };
  const handleSendMessage = async () => {
    setIsLoading(true);
    try {
      const payload = {
        ticketId: id,
        messageText,
        messageMedia,
      };

      const response = await makeApiCall(
        routes.ticket.issue.message,
        "POST",
        payload
      );
      // setData(...data, data?.deletedOn ? null : (data.deletedOn = new Date()));

      // if (response.status) {
      //   // Update the data state based on the action
      // }
    } catch (e) {
    } finally {
      setIsLoading(false);
      navigate(0);
      onClose();
    }
  };
  const handleCancel = () => {
    onClose();
    setMessageText("");
    setMessageMedia(null);
  };
  return (
    <Drawer
      onClick={(event) => {
        event.stopPropagation();
      }}
      anchor="bottom"
      ModalProps={{
        keepMounted: true, // Ensures the modal stays mounted for better performance
      }}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { borderRadius: "16px 16px 0 0", padding: 3, height: "790px" },
      }}
    >
      <LoadingBackdrop isLoading={isLoading} />

      <Box sx={{ mx: "6.5%", mb: 0 }}>
        {/* Modal Heading */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: colors.gray800,
              fontSize: fonts.heading3,
              fontWeight: 600,
              mt: 3,
              mb: 2,
            }}
          >
            Send Message
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mb: 0,
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                mr: 3,
                textTransform: "none",
                background: colors.gray200,
                color: colors.gray800,
              }}
              endIcon={<CloseOutlinedIcon sx={{ color: colors.gray800 }} />}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              endIcon={<CheckOutlinedIcon sx={{ color: colors.green900 }} />}
              sx={{
                textTransform: "none",
                background: colors.green100,
                color: colors.green900,
                px: 2.5,
              }}
              disabled={!messageText}
              onClick={handleSendMessage}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Typography
          sx={{
            color: colors.gray800,
            fontSize: fonts.heading3,
            fontWeight: 600,
            mt: 3,
            mb: 2,
          }}
        >
          Details
        </Typography>
        <Box mt={-1}>
          <DividerLine />
        </Box>

        <TextField
          multiline
          rows={6}
          label="Description"
          type="text"
          variant="outlined"
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          sx={{
            mt: 5,
            width: "100%",
            display: "flex",
            justifyContent: "left",
            textAlign: "left",
            borderColor: colors.gray500,
          }}
        />
        <Box sx={{ position: "relative", display: "flex", mt: 5 }}>
          <Typography
            sx={{
              fontSize: fonts.heading2,
              color: colors.gray800,
              fontWeight: 600,
            }}
          >
            Screenshots & Images (optional)
          </Typography>
        </Box>
        <Box mt={-1}>
          <DividerLine />
        </Box>
        <FileUploader
          showPreviousUploads={false}
          typeImage={true}
          onFileChange={handleFileUpload}
          value={messageMedia}
        />
        <Box mt={-10}></Box>
      </Box>
    </Drawer>
  );
};
export default ReplyMessageModal;
