import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import colors from "../../styles/colors";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MapsUgcOutlinedIcon from "@mui/icons-material/MapsUgcOutlined";
import QrCodeOutlinedIcon from "@mui/icons-material/QrCodeOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import fonts from "../../styles/fontVars";
import ConfirmationDialog from "../utils/Dialog";
import routes from "../../api/routes";
import useApiRequest from "../../api/api";
import { useState } from "react";
import LoadingBackdrop from "../utils/loader";
import { hasPermission } from "../utils/permissions/permissions";
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  position: "fixed", // Make the drawer fixed
  zIndex: 1200, // Ensure it's above other content
  top: 0,
  left: 0,
  height: "100vh", // Full height to cover the page
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const { makeApiCall } = useApiRequest();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTopIndex, setSelectedTopIndex] = useState(0);
  const [selectedBottomIndex, setSelectedBottomIndex] = useState(null);
  const location = useLocation();
  const isActive = (path) => {
    if (path === "/") {
      return location.pathname === "/";
    }
    return location.pathname.startsWith(path);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleItemClick = (path) => {
    if (path) {
      navigate(path);
      setOpen(false);
    }
  };

  const handleLogout = () => {
    setOpenDialog(true);
  };

  const handleConfirm = async () => {
    setIsLoading(true);

    const response = await makeApiCall(routes.account.auth.logout, "DELETE");
    if (response?.status) {
      // Remove user from localStorage
      setIsLoading(false);

      // Optionally navigate to the homepage or login page
      localStorage.removeItem("user");

      navigate("/");
    } else {
    }

    setOpenDialog(false);
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };
  const mainMenuItems = [
    {
      text: "Dashboard",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={colors.gray600}
        >
          <path d="m296-80-56-56 276-277 140 140 207-207 57 57-264 263-140-140L296-80Zm-136-40q-33 0-56.5-23.5T80-200v-560q0-33 23.5-56.5T160-840h560q33 0 56.5 23.5T800-760v168H160v472Zm0-552h560v-88H160v88Zm0 0v-88 88Z" />
        </svg>
      ),
      path: "/dashboard", // Add path for navigation
      permission: "ViewDashboard",
    },
    {
      text: "Users",
      icon: <GroupOutlinedIcon />,
      path: "/users", // Add path for navigation
      permission: "ViewUsers",
    },
    {
      text: "Exercises",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={colors.gray600}
        >
          <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
        </svg>
      ),
      path: "/exercises", // Add path for navigation
      permission: "ViewExercises",
    },
    {
      text: "Newsletter",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={colors.gray600}
        >
          <path d="M280-280q-33 0-56.5-23.5T200-360v-400q0-33 23.5-56.5T280-840h560q33 0 56.5 23.5T920-760v400q0 33-23.5 56.5T840-280H280Zm280-188L280-663v303h560v-303L560-468Zm0-98 280-194H280l280 194ZM120-120q-33 0-56.5-23.5T40-200v-500h80v500h660v80H120Zm720-546v-94H280v94-94h560v94Z" />
        </svg>
      ),
      path: "/newsletter", // Add path for navigation
      permission: "ViewNewsletters",
    },
    {
      text: "Feedback",
      icon: <MapsUgcOutlinedIcon />,
      path: "/feedback",
      permission: "ViewFeedbacks",
    },
    {
      text: "Support Tickets",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={colors.gray600}
        >
          <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 412L346-160H160v-186L28-480l132-134v-186h186l134-132 134 132h186v186l132 134-132 134v186H614L480-28Zm0-112 100-100h140v-140l100-100-100-100v-140H580L480-820 380-720H240v140L140-480l100 100v140h140l100 100Zm0-340Z" />
        </svg>
      ),
      path: "/support", // Add path for navigation
      permission: "ViewSupportTickets",
    },
  ];

  const bottomMenuItems = [
    {
      text: "Landing Page",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill={colors.gray600}
        >
          <path d="M160-80q-33 0-56.5-23.5T80-160v-360q0-33 23.5-56.5T160-600h80v-200q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v360q0 33-23.5 56.5T800-360h-80v200q0 33-23.5 56.5T640-80H160Zm0-80h480v-280H160v280Zm560-280h80v-280H320v120h320q33 0 56.5 23.5T720-520v80Z" />
        </svg>
      ),
      path: "https://trainerjoe.ai",
      permission: "ViewLandingPage",
    },
    {
      text: "Web App",
      icon: <QrCodeOutlinedIcon />,
      path: "https://studio.trainerjoe.ai",
      permission: "ViewWebApp",
    },
    {
      text: "Administrators",
      icon: <LockPersonOutlinedIcon />,
      path: "/admin",
      permission: "ViewInfo",
    },
    {
      text: <Typography sx={{ color: colors.red900 }}>Log Out</Typography>,
      icon: <LogoutOutlinedIcon sx={{ color: colors.red900 }} />,
      permission: "Logout",
    },
  ];
  const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
  const userRole = storedUser.role;

  const renderMenuItems = (items, isTopMenu = true) =>
    items.map((item, index) => {
      if (!hasPermission(userRole, item.permission)) return null;

      const isLastBottomItem = !isTopMenu && item === items[items.length - 1];
      const itemColor = isLastBottomItem ? colors.red900 : colors.gray600;
      const isItemActive = isActive(item.path);
      const handleClick = () => {
        if (isLastBottomItem) {
          handleLogout();
        } else if (item.path.startsWith("http")) {
          // Open external links in a new tab
          window.open(item.path, "_blank", "noopener,noreferrer");
        } else {
          // Navigate to internal paths
          handleItemClick(item.path);
        }
      };
      return (
        <React.Fragment key={item.text}>
          <ListItem
            onClick={handleClick}
            disablePadding
            sx={{
              display: "block",
              backgroundColor: isItemActive ? colors.teal50 : "inherit",
              "& .MuiListItemIcon-root, & .MuiListItemText-root": {
                color: isItemActive ? colors.teal900 : itemColor,
              },
              "& svg": {
                fill: isItemActive
                  ? isLastBottomItem
                    ? colors.red900
                    : colors.teal900
                  : itemColor,
              },
              "&:hover": {
                backgroundColor: isItemActive ? colors.teal50 : colors.gray50,
              },
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: itemColor, // Apply color here as well
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  opacity: open ? 1 : 0,
                  color: itemColor, // Apply color to text
                }}
              />
            </ListItemButton>
          </ListItem>
          {!isTopMenu && index !== items.length - 1 && <Divider />}
        </React.Fragment>
      );
    });

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <LoadingBackdrop isLoading={isLoading} />

      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
      >
        <Box ml={0.5} mt={4} display={"flex"} alignItems={"center"}>
          <IconButton color="inherit">
            <Avatar
              alt="User Avatar"
              src="https://d3vdd33llamc8d.cloudfront.net/assets/icons/logo.png"
            />
          </IconButton>
          <Typography
            ml={1}
            fontSize={fonts.heading3}
            fontWeight={600}
            noWrap
            component="div"
            sx={{
              background: "linear-gradient(to right, #74D0A9, #9388D3)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            {open ? "J.O.E" : ""}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <List>{renderMenuItems(mainMenuItems, true)}</List>
          <Box sx={{ flexGrow: 1 }} />
          <List>{renderMenuItems(bottomMenuItems, false)}</List>
          {/* Pass true for dividers */}
        </Box>
      </Drawer>
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title="Logout"
        content="Are you sure you want to logout of your account?"
        cancelButtonText="Cancel"
        confirmButtonText="Logout"
        cancelButtonProps={{ sx: { color: colors.gray500 } }}
        confirmButtonProps={{ sx: { color: colors.red900 } }}
      />
    </Box>
  );
}
