import React from "react";
import { Grid, Paper, Typography, Box, Divider } from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";

const ExercisesSection = ({ configExerciseData }) => {
  return (
    <Grid item xs={12} md={4} sx={{ mt: 4 }}>
      <Paper
        sx={{
          borderRadius: "8px",
          boxShadow: "0px 4px 20px 0px #00000014",
          pl: 5,
          pt: 4,
          pb: 6,
        }}
        elevation={0}
      >
        <Typography
          fontSize={fonts.heading3}
          fontWeight={600}
          textAlign={"left"}
          gutterBottom
        >
          Exercises
        </Typography>
        <ExerciseItem
          label="Total exercises in the library"
          value={configExerciseData?.totalExercises}
        />
        <Divider sx={{ mt: 4 }} />
        <ExerciseItem
          label="User created exercises"
          value={configExerciseData?.totalUserExercises}
        />
      </Paper>
    </Grid>
  );
};

const ExerciseItem = ({ label, value }) => (
  <Box mt={3.1} display={"flex"}>
    <Box width={10} mt={3} mr={3} sx={{ background: "#E0E0E0" }}></Box>
    <Box>
      <Typography textAlign={"left"} mt={3} color={colors.gray400}>
        {label}
      </Typography>
      <Typography
        textAlign={"left"}
        fontSize={fonts.heading3}
        fontWeight={500}
        color="primary"
        mt={1}
      >
        {value}
      </Typography>
    </Box>
  </Box>
);

export default ExercisesSection;
