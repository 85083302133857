import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Pagination } from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import LandingPageSection from "../../components/dashboard/LandingPageSections";
import SearchAndFilters from "../../components/searchRow/SearchFilter";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GroupRemoveOutlinedIcon from "@mui/icons-material/GroupRemoveOutlined";
import BasicTable from "../../components/table/table";
import { useNavigate } from "react-router";
import useFetchSectionData from "../../hooks/useFetchSessionHook";
import routes from "../../api/routes";
import useDataFetch from "../../hooks/useDataFetch";
import { formatDateToLongForm } from "../../components/utils/DateFormatter";
import LoadingBackdrop from "../../components/utils/loader";
import SmallLoader from "../../components/utils/small_loader";
const Users = () => {
  const navigate = useNavigate();
  const config = {
    fromUser: true, // or true based on your logic
    title: "",
    chartTitle: "Users",
    card1Title: "Users",
    card1Count: "10 000 Users", // Example of user count
    card2Title: "Deleted Users",
    card2Count: "10 000 Users", // Example of user count
    card1Icon: <GroupOutlinedIcon sx={{ color: colors.teal600 }} />,
    card2Icon: <GroupRemoveOutlinedIcon sx={{ color: colors.red300 }} />,
    card1Color: colors.teal600,
    card2Color: colors.red300,
    card1IconBgColor: colors.teal50,
    card2IconBgColor: colors.red100,
    legend1: "Total Users",
    legend1Color: colors.teal600,
  };
  const columns = [
    { field: "id", label: "ID", style: { pr: 8 } },
    { field: "name", label: "Name" },
    { field: "email", label: "Email" },
    { field: "workouts", label: "Workouts" },
    { field: "joined", label: "Joined" },
    {
      field: "status",
      label: "Status",
      cellStyle: (value) => ({
        color: value === "ACTIVE" ? colors.green900 : colors.red900, // Using color codes for green900 and red900
      }),
    },
  ];

  const handleRowClick = (row) => {
    navigate(`/users/details?id=${row.id}`);
    // navigate(`/users/details/`);
  };
  const [period, setPeriod] = useState("thisYear");
  const sections = [
    {
      endpoint: routes.user.get_stats({ period }),
      config,
      key: "user",
    },
  ];

  // const [data, setData] = useState([]); // Store fetched data
  const { data, updateSectionPeriod } = useFetchSectionData(sections, period);
  const {
    filteredData,
    totalPages,
    loading,
    pageNumber,
    handlePageChange,
    handleSearch,
    handleFilterChange,
    handleExportToExcel,
  } = useDataFetch(routes.user, "name");
  const rows = filteredData?.users?.map((data) => ({
    id: data?.id, // Convert ID to string if needed
    name: data?.username || "---",
    email: data?.email || "---",
    workouts: data?.workoutCount, // Format the date
    joined: formatDateToLongForm(new Date(data?.createdOn)), // Format the date
    status: data?.deletedOn ? "RESTRICTED" : "ACTIVE", // Determine status based on email
  }));
  const filters = [
    { label: "Name", value: "name" },
    { label: "Date Joined", value: "dateJoined" },
  ];
  const [loadingCount, setLoadingCount] = useState(0);
  useEffect(() => {
    if (loading) {
      setLoadingCount((prevCount) => prevCount + 1); // Increment counter on loading state change
    }
  }, [loading]); // Track changes to the loading state
  return (
    <Box sx={{ mx: "4%" }}>
      <Typography
        textAlign={"left"}
        fontSize={fonts.heading1}
        fontWeight={600}
        width={"150px"}
        mt={5}
        sx={{
          background: "linear-gradient(90deg, #74D0A9 0%, #9388D3 93.63%);",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
          textFillColor: "transparent",
        }}
      >
        Users
        {loadingCount === 1 && <SmallLoader isLoading={loading} />}
      </Typography>
      <Typography
        mt={0.5}
        textAlign={"left"}
        gutterBottom
        color={colors.gray400}
      >
        See all the users that are making use of the web app
      </Typography>

      <Grid container spacing={3}>
        <LandingPageSection config={data.user} />
      </Grid>
      <Box mt={4}>
        <SearchAndFilters
          onSearch={handleSearch}
          onFilterChange={handleFilterChange}
          exportToExcel={handleExportToExcel}
          filters={filters}
        />
      </Box>
      <Box mt={4}>
        <BasicTable columns={columns} rows={rows} onRowClick={handleRowClick} />
      </Box>
      <Box mb={6}>
        <Pagination
          count={totalPages} // Use dynamic totalPages from state
          page={pageNumber} // Control the current page
          onChange={handlePageChange} // Handle page changes
          color="primary"
          sx={{ mb: 0, justifyContent: "center", display: "flex" }}
        />
      </Box>
    </Box>
  );
};

export default Users;
