import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  InputAdornment,
} from "@mui/material";

import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import WestSharpIcon from "@mui/icons-material/WestSharp";
import colors from "../../styles/colors";
import useWindowWidth from "../windowSize/WindowWidth";
import fonts from "../../styles/fontVars";
import Otp from "./Otp";
import NewPassword from "./NewPassword";
import routes from "../../api/routes";
import useApiRequest from "../../api/api";
import { useNavigate } from "react-router";

const ForgotPw = ({ handleBack, isFromRegister }) => {
  const [showOtp, setShowOtp] = useState(false); // State to toggle between ForgotPw and Otp components
  const [email, setEmail] = useState(""); // State to toggle between ForgotPw and Otp components
  const [step, setStep] = useState(1); // State to manage steps (1 = ForgotPw, 2 = Otp, 3 = NewPassword)
  const [verificationToken, setVerificationToken] = useState("");
  const { makeApiCall } = useApiRequest();
  const navigate = useNavigate();

  // Handle Continue button click in ForgotPw
  const handleContinueToOtp = async () => {
    const payload = {
      email: email,
    };
    const response = await makeApiCall(
      routes.account.auth.password.send_code,
      "POST",
      payload,
      "show"
    );
    if (response.status) {
      setStep(2); // Move to the Otp step
    }
  };

  // Handle Continue button click in Otp
  const handleContinueToNewPassword = async (otp, rememberMeForSevenDays) => {
    const payload = {
      email: email,
      code: otp,
      rememberMeForSevenDays: rememberMeForSevenDays,
    };
    const response = await makeApiCall(
      routes.account.auth.password.verify_code,
      "POST",
      payload
      // "show"
    );
    if (response.status) {
      setVerificationToken(response.data.verificationToken);
      setStep(3); // Move to the Otp step
    }
  };
  const handleContinue = async (password, confirmPassword) => {
    const payload = {
      email: email,
      verificationToken: verificationToken,
      password: password,
      confirmPassword: confirmPassword,
    };
    const response = await makeApiCall(
      routes.account.auth.password.change_pw,
      "PUT",
      payload
      // "show"
    );
    if (response.status) {
      navigate("/");
    }
  };
  const handleResend = async () => {
    const payload = {
      email: email,
    };
    const response = await makeApiCall(
      routes.account.auth.send_code,
      "POST",
      payload,
      "show"
    );
  };
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  return (
    <Box sx={{ width: isMobile ? "100%" : "100%" }}>
      {step === 1 && (
        <>
          <Typography
            sx={{
              color: colors.gray600,
              display: "flex",
              justifyContent: isMobile ? "center" : "left",
              textAlign: "left",
              mt: 0,
              cursor: "pointer",
            }}
            // variant={windowWidth <= 768 ? "h5" : "h4"}
            fontSize={fonts.body}
            gutterBottom
            onClick={handleBack}
          >
            <WestSharpIcon />{" "}
            <Typography sx={{ marginLeft: 1 }}>Back</Typography>{" "}
          </Typography>
          <Typography
            sx={{
              color: colors.gray900,
              display: "flex",
              justifyContent: isMobile ? "center" : "left",
              textAlign: "left",
              mt: 5,
            }}
            // variant={windowWidth <= 768 ? "h5" : "h4"}
            fontWeight="bold"
            fontSize={fonts.heading1}
            gutterBottom
          >
            Forgot Password?
          </Typography>
          <Typography
            sx={{
              ml: isMobile ? 1 : 0,
              display: "flex",
              justifyContent: "left",
              textAlign: "left",
              width: "280px",
              fontSize: fonts.heading2,
              fontWeight: 600,
              color: colors.gray900,
              mt: 7,
            }}
            // variant={isMobile ? "body2" : fonts.body}

            gutterBottom
          >
            Enter your Email
          </Typography>

          <TextField
            fullWidth
            label="Enter your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            sx={{ ml: isMobile ? 0.6 : 0, mt: 5 }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailOutlinedIcon />
                  </InputAdornment>
                ),
              },
            }}
          />

          <Typography
            component="span"
            display={"flex"}
            justifyContent={"left"}
            sx={{
              fontSize: isMobile ? "11px" : fonts.info,
              ml: isMobile ? 2 : 0,
            }}
          >
            Enter the email linked to this account
          </Typography>

          <Button
            fullWidth
            variant="contained"
            onClick={handleContinueToOtp}
            sx={{
              mt: 9,
              py: 1,
              background: colors.teal400,
              height: isMobile ? "45px" : "60px",
              borderRadius: "4px",
              textTransform: "none",
              fontSize: isMobile ? 12 : fonts.heading4,
              letterSpacing: 1,
              fontWeight: 400,
              transition: "box-shadow 0.3s ease",
              "&:hover": {
                boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.4)", // Drop shadow effect
              },
            }}
          >
            Continue
          </Button>
        </>
      )}
      {step === 2 && (
        <Otp
          handleBack={handleBack}
          handleContinue={handleContinueToNewPassword} // Continue from Otp to NewPassword
          handleResend={handleResend}
        />
      )}
      {step === 3 && (
        <NewPassword handleBack={handleBack} handleContinue={handleContinue} />
      )}{" "}
      {/* Render NewPassword component */}
    </Box>
  );
};

export default ForgotPw;
