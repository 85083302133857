import { useEffect, useRef, useState } from "react";
import useApiRequest from "../api/api";
import routes from "../api/routes";

const useFetchSectionData = (sections, period) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const isInitialMount = useRef(true);
  const [localPeriods, setLocalPeriods] = useState({
    landing: period,
    activeUsers: period,
    workouts: period,
    newsletter: period,
    user: period,
    exercise: period,
    suggestion: period,
    issue: period,
  });
  const { makeApiCall } = useApiRequest();

  const fetchSectionData = async (endpoint, config, key, currentPeriod) => {
    // setLoading(true);

    try {
      // Replace the period in the endpoint with the specific period for this section
      const periodSpecificEndpoint = endpoint
        .toString()
        .replace(`period=${period}`, `period=${currentPeriod}`);

      const response = await makeApiCall(periodSpecificEndpoint, "GET");
      if (response && response.data) {
        let updatedLegends = {};
        if (
          !(
            endpoint === routes.dashboard.support ||
            endpoint === routes.dashboard.exercise ||
            endpoint === routes.exercise.get_stats(period)
          )
        ) {
          const legends = response.data.graph.legends || [];
          legends.forEach((legend, index) => {
            updatedLegends[`legend${index + 1}`] = legend;
          });
        }

        const sectionData = {
          landing: {
            ...config, // Merge base config
            card1Count: `${response.data.newsletterSubscriptions} Users`,
            card2Count: `${response.data.socialRedirects} Redirects`,
            graphData: response.data.graph,
            percentChangeInViewership: response.data.percentChangeInViewership,
            ...updatedLegends,
          },

          activeUsers: {
            ...config, // Merge base config
            graphData: response.data.graph,
            totalActiveUsers: response.data.totalActiveUsers || "",
            totalWorkoutsCreated: response.data.totalWorkoutsCreated || "",
            totalWorkoutsPlayed: response.data.totalWorkoutsPlayed || "",
            ...updatedLegends,
          },
          workouts: {
            ...config, // Merge base config
            graphData: response.data.graph,
            totalWorkoutsCreated: response.data.totalWorkoutsCreated || 0,
            totalWorkoutsPlayed: response.data.totalWorkoutsPlayed || 0,
            ...updatedLegends,
          },
          support: {
            ...config, // Merge base config
            totalOpenTickets: response.data.totalOpenTickets || 0,
            totalSuggestions: response.data.totalSuggestions || 0,
          },
          exercise_summary: {
            ...config, // Merge base config
            totalExercises: response.data.totalExercises || 0,
            totalUserExercises: response.data.totalUserExercises || 0,
          },
          newsletter: {
            ...config,
            card1Count: `${response.data.totalUnRegisteredMembers} Users`,
            card2Count: `${response.data.totalRegisteredMembers} Users`,
            graphData: response.data.graph,
          },
          user: {
            ...config,
            card1Count: `${response.data.totalActiveUsers} Users`,
            card2Count: `${response.data.totalRestrictedUsers} Users`,
            graphData: response.data.graph,
          },
          exercise: {
            ...config,
            card1Count: `${response.data.totalAvailableExercises} Exercises`,
            card2Count: `${response.data.totalUserExercises} Exercises`,
            card3Count: `${response.data.totalArchivedExercises} Exercises`,
            graphData: response.data.topExercises,
          },
          suggestion: {
            ...config,
            card1Count: `${response.data.totalSuggestions} Messages`,
            card2Count: `${response.data.totalImportantSuggestions} Messages`,
            graphData: response.data.graph,
          },
          issue: {
            ...config,
            card1Count: `${response.data.totalClosedTickets} Tickets`,
            card2Count: `${response.data.totalOpenTickets} Tickets`,
            graphData: response.data.graph,
          },
        };

        // Dynamically update state for the specific section
        setData((prevData) => ({
          ...prevData,
          [key]: sectionData[key],
        }));
      }
    } catch (error) {
      console.error(`Error fetching data for section [${key}]:`, error);
    } finally {
      // setLoading(false); // Stop loading after all data is fetched
    }
  };

  const updateSectionPeriod = (sectionKey, newPeriod) => {
    setLocalPeriods((prev) => ({
      ...prev,
      [sectionKey]: newPeriod,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isInitialMount.current) {
        setLoading(true);
      }

      await Promise.all(
        sections.map(({ endpoint, config, key }) =>
          fetchSectionData(endpoint, config, key, localPeriods[key])
        )
      );

      if (isInitialMount.current) {
        setLoading(false);
        setInitialLoadComplete(true);
        isInitialMount.current = false;
      }
    };

    fetchData();
  }, [localPeriods]);

  return {
    data,
    updateSectionPeriod,
    loading: loading && !initialLoadComplete,
  };
};

export default useFetchSectionData;
