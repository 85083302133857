import React from "react";
import { Paper, Box, Typography, IconButton } from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";

export default function NewsletterSubscriptions({ config }) {
  return (
    <Paper
      elevation={0}
      sx={{
        mb: 2,
        boxShadow: "0px 4px 20px 0px #00000014",
        borderRadius: "6px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pl={2}
      >
        <Typography mt={-3} textAlign={"left"} color={colors.gray800}>
          {config?.card1Title}
        </Typography>
        <IconButton
          sx={{ background: config?.card1IconBgColor, p: 2, m: 2 }}
          size="small"
        >
          {config?.card1Icon}
        </IconButton>
      </Box>
      <Typography
        display={"flex"}
        justifyContent={"start"}
        fontSize={fonts.heading2}
        fontWeight={500}
        color={config?.card1Color}
        pb={1}
        borderBottom={3}
        borderRadius={"6px"}
        mt={2}
        textAlign={"left"}
        pl={2}
      >
        {config?.card1Count}
      </Typography>
    </Paper>
  );
}
