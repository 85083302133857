import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { styled } from "@mui/system";
import previousImage from "../../assets/previousImages.svg";
import colors from "../../styles/colors";
import edit from "../../assets/edit.svg";
import { useSnackbar } from "./SnackbarProvider";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const UploadBox = styled(Box)({
  border: "2px dashed #ddd",
  padding: "40px",
  textAlign: "center",
  borderRadius: "8px",
  cursor: "pointer",
  "&:hover": {
    borderColor: "#26A69A",
  },
});

const PreviousUploadsContainer = styled(Box)({
  position: "relative",
  width: "100%",
  marginTop: "16px",
});

const PreviousUploads = styled(Box)({
  display: "flex",
  overflowX: "auto",
  scrollBehavior: "smooth",
  WebkitOverflowScrolling: "touch", // For smooth scrolling on iOS
  padding: "4px", // Add padding to show scroll shadow
  gap: "20px",

  // Hide scrollbar for Chrome, Safari and Opera
  "&::-webkit-scrollbar": {
    display: "none",
  },

  // Hide scrollbar for IE, Edge and Firefox
  msOverflowStyle: "none", // IE and Edge
  scrollbarWidth: "none", // Firefox

  "& img": {
    minWidth: "100px", // Prevent images from shrinking
    width: "100px",
    height: "100px",
    borderRadius: "8px",
    objectFit: "cover",
    transition: "transform 0.2s",

    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    },
  },
});

// Optional: Add scroll buttons if you want them
const ScrollButton = styled(IconButton)({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  zIndex: 1,

  "&.left": {
    left: -25,
  },

  "&.right": {
    right: -25,
  },
});

const Arrow = ({ direction, onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        backgroundColor: colors.gray200,
        borderRadius: "50%",
        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
        "&:hover": {
          backgroundColor: "white",
        },
      }}
    >
      {direction === "next" ? <ArrowForwardIcon /> : <ArrowBackIcon />}
    </IconButton>
  );
};
const SelectedImageContainer = styled(Box)({
  display: "flex",
  justifyContent: "left",
  marginTop: 5,
  width: "400px",
  "& img": {
    width: "300px",
    height: "180px",
    borderRadius: "8px",
    objectFit: "cover",
  },
  overflow: "hidden",
});

const MultipleImagesContainer = styled(Box)({
  display: "flex",
  gap: "16px",
  marginTop: "16px",
});

const ButtonColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "35px",
  padding: "16px",
  backgroundColor: "#fff",
});

const FileUploader = ({
  showPreviousUploads = true,
  typeImage = false,
  onFileChange,
  value,
  isEdit,
}) => {
  const showSnackbar = useSnackbar(); // Use the global Snackbar
  const { makeApiCall } = useApiRequest();
  const [selectedFile, setSelectedFile] = useState(value || null);
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [previousUploads, setPreviousUploads] = useState([]);
  const scrollContainerRef = useRef(null);
  // useEffect(() => {
  //   if (showPreviousUploads) {
  //     fetchPreviousUploads();
  //   }
  // }, [showPreviousUploads]);

  // const fetchPreviousUploads = async () => {
  //   const response = await makeApiCall(routes.profile.uploads, "GET");
  //   if (response?.data?.uploads) {
  //     setPreviousUploads(response.data.uploads);
  //   }
  //   setIsLoading(false);
  // };
  useEffect(() => {
    if (value === null) {
      setSelectedFile(null);
      setMultipleFiles([]);
    }
  }, [value]);
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      const newScrollLeft =
        scrollContainerRef.current.scrollLeft +
        (direction === "prev" ? -scrollAmount : scrollAmount);
      scrollContainerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };
  const handleFileChange = async (event) => {
    if (showPreviousUploads) {
      const file = event.target.files[0];

      if (file) {
        if (file.size <= 15 * 1024 * 1024) {
          const base64File = await convertToBase64(file);
          setSelectedFile(URL.createObjectURL(file));
          if (onFileChange) {
            onFileChange(base64File);
          }
        } else {
          showSnackbar(`File size exceeds 15MB`, "error");

          setSelectedFile(null);
        }
      }
    } else {
      const files = Array.from(event.target.files);

      // Handle file editing
      if (editingIndex !== null) {
        const file = files[0];
        if (file && file.size <= 15 * 1024 * 1024) {
          const base64File = await convertToBase64(file);
          const newFiles = [...multipleFiles];
          newFiles[editingIndex] = {
            url: URL.createObjectURL(file),
            base64: base64File,
          };
          setMultipleFiles(newFiles);
          if (onFileChange) {
            onFileChange(newFiles.map((f) => f.base64));
          }
        } else if (file) {
          showSnackbar(`File size exceeds 15MB`, "error");

          // alert("You can only upload up to 3 files");
        }
        setEditingIndex(null);
      } else {
        // Handle new file addition
        if (files.length + multipleFiles.length > 3) {
          showSnackbar("You can only upload up to 3 files", "error");

          return;
        }

        const newFiles = await Promise.all(
          files.map(async (file) => {
            if (file.size <= 15 * 1024 * 1024) {
              const base64File = typeImage ? await convertToBase64(file) : null;
              return {
                url: URL.createObjectURL(file),
                file: file,
                base64: base64File,
              };
            } else {
              showSnackbar(`File ${file.name} exceeds 15MB`, "error");

              return null;
            }
          })
        );

        const validFiles = [
          ...multipleFiles,
          ...newFiles.filter((f) => f !== null),
        ];
        setMultipleFiles(validFiles.slice(0, 3));
        if (onFileChange) {
          onFileChange(validFiles.map((f) => (typeImage ? f.base64 : f.file)));
        }
      }
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    if (showPreviousUploads) {
      const file = event.dataTransfer.files[0];
      if (file && file.size <= 15 * 1024 * 1024) {
        const base64File = await convertToBase64(file);
        setSelectedFile(URL.createObjectURL(file));
        if (onFileChange) {
          onFileChange(base64File);
        }
      } else {
        showSnackbar("File size exceeds 15MB", "error");
        setSelectedFile(null);
      }
    } else {
      const files = Array.from(event.dataTransfer.files);

      // Handle file editing for drag and drop
      if (editingIndex !== null) {
        const file = files[0];
        if (file && file.size <= 15 * 1024 * 1024) {
          const base64File = await convertToBase64(file);
          const newFiles = [...multipleFiles];
          newFiles[editingIndex] = {
            url: URL.createObjectURL(file),
            base64: base64File,
          };
          setMultipleFiles(newFiles);
          if (onFileChange) {
            onFileChange(newFiles.map((f) => f.base64));
          }
        } else if (file) {
          showSnackbar("File size exceeds 15MB", "error");
        }
        setEditingIndex(null);
      } else {
        // Handle new file addition
        if (files.length + multipleFiles.length > 3) {
          showSnackbar("You can only upload up to 3 files", "error");

          return;
        }

        const newFiles = await Promise.all(
          files.map(async (file) => {
            if (file.size <= 15 * 1024 * 1024) {
              const base64File = await convertToBase64(file);
              return {
                url: URL.createObjectURL(file),
                base64: base64File,
              };
            } else {
              showSnackbar(`File ${file.name} exceeds 15MB`, "error");

              return null;
            }
          })
        );

        const validFiles = [
          ...multipleFiles,
          ...newFiles.filter((f) => f !== null),
        ];
        setMultipleFiles(validFiles.slice(0, 3));
        if (onFileChange) {
          onFileChange(validFiles.map((f) => f.base64));
        }
      }
    }
  };

  const handleDelete = (index) => {
    if (showPreviousUploads) {
      setSelectedFile(null);
      onFileChange(null);
    } else {
      const newFiles = multipleFiles.filter((_, i) => i !== index);
      setMultipleFiles(newFiles);
      if (onFileChange) {
        onFileChange(newFiles.map((f) => f.base64));
      }
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handlePreviousImageClick = (imageUrl) => {
    setSelectedFile(imageUrl);
    if (onFileChange) {
      onFileChange(imageUrl);
    }
  };

  const shouldShowUploadBox = showPreviousUploads
    ? !selectedFile || !value
    : multipleFiles.length < 3;
  return (
    <Box sx={{ mt: 8, mb: 12 }}>
      {!typeImage && (value?.length > 0 || selectedFile) ? (
        <Box
          display="flex"
          width="500px"
          sx={{
            borderRadius: "4px",
            // overflow: "hidden",
            boxShadow:
              "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
          }}
        >
          <video
            controls
            src={value || selectedFile}
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "4px",
            }}
          >
            Your browser does not support the video tag.
          </video>
          {!isEdit && (
            <ButtonColumn>
              <IconButton
                onClick={() => handleDelete(0)}
                size="small"
                sx={{
                  color: colors.red100,
                  borderRadius: "50%",
                  padding: "10px",
                  "&:hover": {
                    background: colors.red100,
                  },
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill={colors.red900}
                >
                  <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                </svg>
              </IconButton>
            </ButtonColumn>
          )}
        </Box>
      ) : (
        <>
          <input
            type="file"
            id="file-input"
            ref={fileInputRef}
            accept={
              typeImage ? ".svg, .png, .jpg, .gif" : ".mp4, .mov, .avi, .webm"
            }
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple={!showPreviousUploads && editingIndex === null}
          />

          {shouldShowUploadBox && (
            <UploadBox
              onClick={() => {
                setEditingIndex(null);
                fileInputRef.current.click();
              }}
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <IconButton sx={{ fontSize: 50, color: "#26A69A" }}>
                <UploadFileOutlinedIcon fontSize="inherit" />
              </IconButton>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <span style={{ color: "#26A69A", cursor: "pointer" }}>
                  Click to upload
                </span>{" "}
                or drag and drop
              </Typography>
              <Typography variant="body2" sx={{ color: "#888", mt: 1 }}>
                {typeImage
                  ? "SVG, PNG, JPG or GIF (max. 3MB)"
                  : "MP4, MOV, AVI or WEBM (max. 15MB)"}
              </Typography>
              {!showPreviousUploads && (
                <Typography variant="body2" sx={{ color: "#888", mt: 1 }}>
                  {typeImage
                    ? `You can upload up to ${
                        3 - multipleFiles.length
                      } more file${3 - multipleFiles.length !== 1 ? "s" : ""}`
                    : ""}
                </Typography>
              )}
            </UploadBox>
          )}

          {!selectedFile && showPreviousUploads && (
            <>
              {isLoading ? (
                <></>
              ) : (
                // <PreviousUploadsSkeleton />
                previousUploads.length > 0 && (
                  <>
                    <Typography
                      color={colors.gray800}
                      sx={{ mt: 3, textAlign: "left" }}
                    >
                      Or select from previous uploads
                    </Typography>
                    <PreviousUploadsContainer>
                      {/* Scroll buttons */}
                      <ScrollButton className="left" size="small">
                        <Arrow
                          direction="prev"
                          className="left"
                          onClick={() => handleScroll("prev")}
                        />
                      </ScrollButton>
                      <ScrollButton className="right" size="small">
                        <Arrow
                          direction="next"
                          className="right"
                          onClick={() => handleScroll("next")}
                        />
                      </ScrollButton>

                      <PreviousUploads ref={scrollContainerRef}>
                        {previousUploads.map((imageUrl, index) => (
                          <img
                            key={index}
                            src={imageUrl}
                            alt={`Previous Upload ${index + 1}`}
                            onClick={() => handlePreviousImageClick(imageUrl)}
                            style={{ cursor: "pointer" }}
                            loading="lazy"
                          />
                        ))}
                      </PreviousUploads>
                    </PreviousUploadsContainer>
                  </>
                )
              )}
            </>
          )}

          {showPreviousUploads && selectedFile && (
            <Box
              display="flex"
              width="400px"
              sx={{
                borderRadius: "4px",
                overflow: "hidden",
                boxShadow:
                  "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
              }}
            >
              <SelectedImageContainer>
                <img src={selectedFile} alt="Selected Upload" />
              </SelectedImageContainer>
              <ButtonColumn>
                <IconButton
                  size="small"
                  onClick={handleEdit}
                  sx={{
                    borderRadius: "50%",
                    padding: "10px",
                    "& img": {
                      filter: "brightness(0) invert(0)",
                    },
                    "&:hover": {
                      background: colors.gray600,
                      "& img": {
                        filter: "brightness(0) invert(1)",
                      },
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={edit}
                    alt="edit"
                    sx={{ cursor: "pointer" }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => handleDelete(0)}
                  size="small"
                  sx={{
                    color: colors.red100,
                    borderRadius: "50%",
                    padding: "10px",
                    "&hover": {
                      background: colors.red100,
                    },
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill={colors.red900}
                  >
                    <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                  </svg>
                </IconButton>
              </ButtonColumn>
            </Box>
          )}

          {!showPreviousUploads && (
            <MultipleImagesContainer>
              {multipleFiles.map((file, index) => (
                <Box
                  key={index}
                  display="flex"
                  width="400px"
                  sx={{
                    borderRadius: "4px",
                    overflow: "hidden",
                    boxShadow:
                      "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
                  }}
                >
                  <SelectedImageContainer>
                    <img src={file.url} alt={`Upload ${index + 1}`} />
                  </SelectedImageContainer>
                  <ButtonColumn>
                    <IconButton
                      size="small"
                      onClick={() => handleEdit(index)}
                      sx={{
                        borderRadius: "50%",
                        padding: "10px",
                        "& img": {
                          filter: "brightness(0) invert(0)",
                        },
                        "&:hover": {
                          background: colors.gray600,
                          "& img": {
                            filter: "brightness(0) invert(1)",
                          },
                        },
                      }}
                    >
                      <Box
                        component="img"
                        src={edit}
                        alt="edit"
                        sx={{ cursor: "pointer" }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(index)}
                      size="small"
                      sx={{
                        color: colors.red100,
                        borderRadius: "50%",
                        padding: "10px",
                        "&:hover": {
                          background: colors.red100,
                        },
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill={colors.red900}
                      >
                        <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                      </svg>
                    </IconButton>
                  </ButtonColumn>
                </Box>
              ))}
            </MultipleImagesContainer>
          )}
        </>
      )}
    </Box>
  );
};

export default FileUploader;
