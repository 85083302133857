// src/components/common/SmallLoader.jsx
import React from "react";
import { CircularProgress } from "@mui/material";

const SmallLoader = ({ isLoading }) => {
  return (
    isLoading && (
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          marginLeft: "8px", // Space between text and loader
        }}
      >
        <CircularProgress size={24} thickness={6} color="primary" />
      </div>
    )
  );
};

export default SmallLoader;
