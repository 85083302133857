import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  Alert,
} from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BasicTable from "../../components/table/table";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DividerLine from "../../components/utils/DividerLine";
import { Add, Check } from "@mui/icons-material";
import { useNavigate } from "react-router";
import DynamicRadioGroup from "../../components/utils/RadioButtons";
import ConfirmationDialog from "../../components/utils/Dialog";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
import { formatDateToLongForm } from "../../components/utils/DateFormatter";
import { hasPermission } from "../../components/utils/permissions/permissions";
const Admin = () => {
  const navigate = useNavigate();
  const { makeApiCall } = useApiRequest();

  const [filteredData, setFilteredData] = useState([]);
  const handleAddAdmin = () => {
    navigate("/admin/add");
  };
  const config = {
    fromExercise: true, // or true based on your logic
    title: "",
    chartTitle: "Top Used Exercises",
    card1Title: "Available Exercises",
    card1Count: "10 000 Exercises", // Example of user count
    card2Title: "Used Exercises",
    card2Count: "10 000 Exercises", // Example of user count
    card3Title: "Archived Exercises",
    card3Count: "10 000 Exercises", // Example of user count

    card1Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.teal600}
      >
        <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
      </svg>
    ),
    card2Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.purple}
      >
        <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
      </svg>
    ),
    card3Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.red900}
      >
        <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
      </svg>
    ),
    card1Color: colors.teal600,
    card2Color: colors.purple,
    card3Color: colors.red900,
    card1IconBgColor: colors.teal50,
    card2IconBgColor: "#EEE1FF",
    card3IconBgColor: colors.red100,
    legend1: "Open",
    legend2: "Closed",
  };

  const columns = [
    { field: "id", label: "ID", style: { pr: 8 } },
    { field: "name", label: "Name" },
    { field: "email", label: "Email" },
    { field: "added", label: "Added" },
    {
      field: "status",
      label: "Status",
      cellStyle: (value) => ({
        color: colors.purple, // Using color codes for green900 and red900
      }),
    },
  ];

  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [showLoading, setShowLoading] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [newpassword, setNewPassword] = React.useState("");
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [selectedPermission, setSelectedPermission] = useState("");

  const permissionDetails = {
    admin: {
      label: "Super Administrator",
      description: "Users with this permission can",
      capabilities: [
        "Create new admins with any permissions",
        "Alter existing admins",
        "View and alter exercise data",
        "View and alter user data",
        "View and alter customer support messages",
      ],
    },
    read: {
      label: "Read Only",
      description: "Users with this permission can",
      capabilities: [
        "View all data without editing capabilities",
        "Access reports and analytics",
      ],
    },
    inventory: {
      label: "Exercise Inventory",
      description: "Users with this permission can",
      capabilities: [
        "Manage exercise database",
        "Add, edit, or remove exercises",
        "Categorize and tag exercises",
      ],
    },
    support: {
      label: "Customer Support",
      description: "Users with this permission can",
      capabilities: [
        "Access customer inquiries and tickets",
        "Respond to customer messages",
        "View user account details for support purposes",
      ],
    },
  };

  const handlePermissionChange = (event) => {
    setSelectedPermission(event.target.value);
  };
  const handleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleConfirm = async () => {
    // Handle the actual logout logic here (e.g., clearing user session, tokens, etc.)
    setShowLoading(true);
    try {
      const response = await makeApiCall(routes.account.delete, "DELETE");
      if (response.status) {
        localStorage.removeItem("user");
        navigate("/");
      }
    } catch (e) {
    } finally {
      setShowLoading(false);
    }
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCancel = () => {
    setOpenDialog(false);
  };
  const handleRowClick = (row) => {
    navigate(`/admin/details?id=${row.id}`);
  };
  const fetchData = async () => {
    const response = await makeApiCall(routes.super_admin_options.list, "GET");
    setFilteredData(response.data);
  };
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const userRole = storedUser?.role;

  useEffect(() => {
    if (hasPermission(userRole, "ViewAdmins")) {
      fetchData();
    }
    setSelectedPermission(storedUser.role);
    setUsername(storedUser.username);
    setEmail(storedUser.email);
  }, []);

  const rows = filteredData?.admins
    ?.filter((data) => data?.id !== storedUser?.id) // Exclude the storedUser's ID
    ?.map((data) => ({
      id: data?.id, // Convert ID to string if needed
      name: data?.username || "---",
      email: data?.email || "---",
      added: formatDateToLongForm(data?.createdOn), // Format the date
      status:
        data?.role === "admin"
          ? "SUPER ADMIN"
          : data?.role === "read"
          ? "READ ONLY ADMIN"
          : data?.role === "support"
          ? "CUSTOMER SUPPORT ADMIN"
          : data?.role === "inventory"
          ? "EXERCISE INVENTORY ADMIN"
          : "---", // Default fallback
    }));

  const handleChangePassword = async () => {
    setShowLoading(true);
    try {
      const payload = {
        email: email,
        oldPassword: currentPassword,
        password: newpassword,
        confirmPassword: confirmPassword,
      };
      const response = await makeApiCall(
        routes.account.auth.password.change_pw,
        "PUT",
        payload
      );
    } catch (e) {
    } finally {
      setShowLoading(false);
    }
  };
  return (
    <Box sx={{ mx: "4%", mb: 8 }}>
      <Grid display="flex" justifyContent={"space-between"} mt={5}>
        <Box>
          <Typography
            textAlign={"left"}
            fontSize={fonts.heading1}
            fontWeight={600}
            width={"300px"}
            mt={0}
            sx={{
              background: "linear-gradient(90deg, #74D0A9 0%, #9388D3 93.63%);",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            Administrators
          </Typography>
          <Typography
            mt={0.5}
            textAlign={"left"}
            gutterBottom
            color={colors.gray400}
          >
            Quickly look through the most important visually represented
            insights
          </Typography>
        </Box>
      </Grid>
      <Typography
        textAlign={"left"}
        fontSize={fonts.heading2}
        color={colors.gray800}
        fontWeight={600}
        mt={4}
      >
        Details
      </Typography>

      <Box mt={-2} sx={{ width: "620px" }}>
        <TextField
          label="Username"
          variant="outlined"
          disabled
          value={username}
          sx={{
            mb: 2,
            mt: 6,
            width: "80%",
            display: "flex",
            justifyContent: "start",
          }}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleOutlinedIcon />
                </InputAdornment>
              ),
            },
          }}
        />
        <TextField
          label="Email"
          variant="outlined"
          disabled
          value={email}
          sx={{
            mb: 4,
            mt: 4,
            width: "80%",
            display: "flex",
            justifyContent: "start",
          }}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailOutlinedIcon />
                </InputAdornment>
              ),
            },
          }}
        />

        <Typography
          fontSize={fonts.heading3}
          color={colors.gray800}
          fontWeight={600}
          textAlign={"left"}
          mt={7}
        >
          Change Password
        </Typography>
        <TextField
          label="Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          type={showCurrentPassword ? "text" : "password"}
          margin="normal"
          sx={{
            mt: 4,
            width: "80%",
            display: "flex",
            justifyContent: "start",
          }}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleCurrentPassword}
                  >
                    {showCurrentPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <TextField
          sx={{
            mt: 3,
            width: "80%",
            display: "flex",
            justifyContent: "start",
          }}
          label="New Password"
          value={newpassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type={showNewPassword ? "text" : "password"}
          margin="normal"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleNewPassword}
                  >
                    {showNewPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <TextField
          sx={{
            mt: 3,
            width: "80%",
            display: "flex",
            justifyContent: "start",
          }}
          label="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type={showConfirmPassword ? "text" : "password"}
          margin="normal"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleConfirmPassword}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <Button
          variant="contained"
          fontSize={fonts.heading4}
          size="large"
          endIcon={<Check />}
          sx={{
            textTransform: "none",
            background: colors.teal400,
            fontWeight: 400,
            height: "40px",
            px: 2,
            display: "flex",
            justifyContent: "start",
            mt: 4,
          }}
          disabled={!(currentPassword && newpassword && confirmPassword)}
          onClick={handleChangePassword}
        >
          Save
        </Button>
      </Box>
      <Typography
        textAlign={"left"}
        fontSize={fonts.heading2}
        color={colors.gray800}
        fontWeight={600}
        mt={7}
      >
        Permissions
      </Typography>
      <DynamicRadioGroup
        options={permissionDetails}
        selectedValue={selectedPermission}
        onChange={handlePermissionChange}
        colors={colors}
        fonts={fonts}
        disabled={true}
      />
      <Grid item xs={12} mt={7} md={12}>
        <Typography
          fontSize={fonts.heading3}
          color={colors.gray800}
          fontWeight={600}
          textAlign={"left"}
        >
          Close Account
        </Typography>
        <Alert
          variant="outlined"
          severity="error"
          sx={{
            mt: 2,
            background: colors.red100,
            color: colors.red900,
            border: "2px solid", // Set the border width to 2px
            borderColor: colors.red900, // Optional: customize border color if needed
            textAlign: "left",
          }}
        >
          This action is be irreversible. You’ll lose all your account
          privileges and you will need to contact a super admin to create
          another account.
        </Alert>
        <Button
          variant="contained"
          sx={{
            display: "flex",
            justifyContent: "left",
            mt: 4,
            mb: 6,
            textTransform: "none",
            fontSize: fonts.heading4,
            background: colors.red900,
            color: colors.red100,
          }}
          onClick={handleOpenDialog}
          endIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#e8eaed"
            >
              <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
            </svg>
          }
        >
          Close My Account
        </Button>
      </Grid>
      {hasPermission(userRole, "ViewAdmins") && (
        <>
          <DividerLine />
          <Box display="flex" justifyContent={"space-between"} mt={5}>
            <Typography
              textAlign={"left"}
              fontSize={fonts.heading2}
              color={colors.gray800}
              fontWeight={600}
            >
              Accounts Registered
            </Typography>
            {hasPermission(userRole, "UpdateAdmins") && (
              <Button
                variant="contained"
                fontSize={fonts.heading4}
                size="large"
                endIcon={<Add />}
                sx={{
                  marginLeft: 2,
                  textTransform: "none",
                  background: colors.teal400,
                  fontWeight: 400,
                  height: "40px",
                  width: "150px",
                  px: 2,
                }}
                onClick={handleAddAdmin}
              >
                Add Admin
              </Button>
            )}
          </Box>
          <Box mt={4}>
            <BasicTable
              columns={columns}
              rows={rows}
              onRowClick={handleRowClick}
            />
          </Box>
        </>
      )}
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title="Delete"
        content="Are you sure you want to delete your account? This action is irreversible"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        cancelButtonProps={{ sx: { color: colors.gray500 } }}
        confirmButtonProps={{ sx: { color: colors.red900 } }}
      />
    </Box>
  );
};

export default Admin;
