export const rolePermissions = {
  admin: [
    "ViewDashboard",
    "ViewUsers",
    "ViewExercises",
    "ViewNewsletters",
    "ViewFeedbacks",
    "ViewSupportTickets",
    "ViewAdmins",
    "RestrictUser",
    "SendUserEmail",
    "UpdateSupportTickets",
    "UpdateExercises",
    "UpdateAdmins",
    "ViewLandingPage",
    "ViewWebApp",
    "ViewInfo",
    "Logout",
  ],
  read: [
    "ViewDashboard",
    "ViewUsers",
    "ViewExercises",
    "ViewNewsletters",
    "ViewFeedbacks",
    "ViewSupportTickets",
    "ViewLandingPage",
    "ViewWebApp",
    "ViewInfo",
    "Logout",
  ],
  inventory: [
    "ViewExercises",
    "UpdateExercises",
    "ViewLandingPage",
    "ViewWebApp",
    "ViewInfo",
    "Logout",
  ],
  support: [
    "ViewUsers",
    "ViewFeedbacks",
    "ViewSupportTickets",
    "SendUserEmail",
    "UpdateSupportTickets",
    "ViewLandingPage",
    "ViewWebApp",
    "ViewInfo",
    "Logout",
  ],
};

export const getDashboardByRole = (role) => {
  switch (role) {
    case "admin":
      return "/dashboard";
    case "read":
      return "/dashboard";
    case "support":
      return "/users";
    case "inventory":
      return "/exercises";
    default:
      return "/";
  }
};

export const hasPermission = (role, permission) => {
  return rolePermissions[role]?.includes(permission) || false;
};
