// src/components/common/LoadingBackdrop.jsx
import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const LoadingBackdrop = ({ isLoading }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.modal + 200, // Ensure it's higher than Dialog
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
      open={isLoading}
    >
      <CircularProgress size={40} thickness={4} color="inherit" />
    </Backdrop>
  );
};

export default LoadingBackdrop;
