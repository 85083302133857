import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  Breadcrumbs,
  Link,
  TextField,
  Alert,
  Button,
} from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";

import DividerLine from "../../components/utils/DividerLine";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileUploader from "../../components/utils/FileUploader";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import useApiRequest from "../../api/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../../api/routes";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from "uuid";
import LoadingBackdrop from "../../components/utils/loader";
import { hasPermission } from "../../components/utils/permissions/permissions";
const AddExercise = () => {
  const { makeApiCall } = useApiRequest();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState("");
  const id = searchParams.get("id");
  const config = {
    fromExercise: true, // or true based on your logic
    title: "",
    chartTitle: "Top Used Exercises",
    card1Title: "Available Exercises",
    card1Count: "10 000 Exercises", // Example of user count
    card2Title: "Used Exercises",
    card2Count: "10 000 Exercises", // Example of user count
    card3Title: "Archived Exercises",
    card3Count: "10 000 Exercises", // Example of user count

    card1Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.teal600}
      >
        <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
      </svg>
    ),
    card2Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.purple}
      >
        <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
      </svg>
    ),
    card3Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={colors.red900}
      >
        <path d="m826-585-56-56 30-31-128-128-31 30-57-57 30-31q23-23 57-22.5t57 23.5l129 129q23 23 23 56.5T857-615l-31 30ZM346-104q-23 23-56.5 23T233-104L104-233q-23-23-23-56.5t23-56.5l30-30 57 57-31 30 129 129 30-31 57 57-30 30Zm397-336 57-57-303-303-57 57 303 303ZM463-160l57-58-302-302-58 57 303 303Zm-6-234 110-109-64-64-109 110 63 63Zm63 290q-23 23-57 23t-57-23L104-406q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l63 63 110-110-63-62q-23-23-23-57t23-57l57-57q23-23 56.5-23t56.5 23l303 303q23 23 23 56.5T857-441l-57 57q-23 23-57 23t-57-23l-62-63-110 110 63 63q23 23 23 56.5T577-161l-57 57Z" />
      </svg>
    ),
    card1Color: colors.teal600,
    card2Color: colors.purple,
    card3Color: colors.red900,
    card1IconBgColor: colors.teal50,
    card2IconBgColor: "#EEE1FF",
    card3IconBgColor: colors.red100,
    legend1: "Open",
    legend2: "Closed",
  };
  const [showCircularProgressBar, setShowCircularProgressBar] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const handleCircularProgressBarToggle = async (event) => {
    setShowCircularProgressBar(event.target.checked);
    const payload = {
      title: exerciseName,
      description: description,
      isActive: event.target.checked,
    };
    const response = await makeApiCall(
      routes.exercise.update(id),
      "PUT",
      payload
    );
  };
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const userRole = storedUser?.role;
  const [media, setMedia] = useState(null); // Array to hold selected images

  const [exerciseName, setExerciseName] = useState("");
  const [description, setDescription] = useState("");
  const [isProcessing, setIsProcessing] = useState("");

  const AcuteIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={colors.gray600}
      style={{ marginRight: 10 }}
    >
      <path d="M600-160q-134 0-227-93t-93-227q0-133 93-226.5T600-800q133 0 226.5 93.5T920-480q0 134-93.5 227T600-160Zm0-80q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Zm91-91 57-57-108-108v-144h-80v177l131 132ZM80-600v-80h160v80H80ZM40-440v-80h200v80H40Zm40 160v-80h160v80H80Zm520-200Z" />
    </svg>
  );
  const handleExerciseNameChange = (event) => {
    setExerciseName(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const [originalData, setOriginalData] = useState({
    exerciseName: "",
    description: "",
    media: null,
    showCircularProgressBar: true,
  });
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await makeApiCall(routes.exercise.get(id), "GET");
      const exerciseData = response.data.exercise;

      setData(exerciseData);
      setIsProcessing(exerciseData.videoCdnUrl ? false : true);
      setDescription(exerciseData.description);
      setExerciseName(exerciseData.title);
      setMedia(exerciseData.videoCdnUrl);
      setOriginalData({
        exerciseName: exerciseData.title,
        description: exerciseData.description,
        media: exerciseData.videoCdnUrl,
        showCircularProgressBar: true, // default value
      });
      setShowCircularProgressBar(exerciseData?.deletedOn ? false : true);
    } catch (e) {
      navigate("/exercises");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const isSaveDisabled = () => {
    // Check for new exercise creation
    if (!id) {
      return !exerciseName || !media;
    }

    // Check for existing exercise
    return (
      exerciseName === originalData.exerciseName &&
      media === originalData.media &&
      showCircularProgressBar === originalData.showCircularProgressBar
    );
  };

  const handleFileChange = (file) => {
    if (file[0]) {
      var getFile = file[0];
      setFile(getFile);
      const fileExtension = getFile.name.split(".").pop();
      setMedia(`temp/${uuidv4()}.${fileExtension}`);
    } else setMedia(null);
  };

  async function handleFileUpload() {
    const target = {
      Bucket: process.env.REACT_APP_BUCKET,
      Key: media,
      Body: file,
    };
    const creds = {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    };

    return new Promise((resolve, reject) => {
      try {
        const parallelUploads3 = new Upload({
          client: new S3Client({
            region: process.env.REACT_APP_REGION,
            credentials: creds,
          }),
          leavePartsOnError: false,
          params: target,
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {});

        parallelUploads3.done().then(resolve).catch(reject);
      } catch (e) {
        console.error("Error during upload:", e);
        reject(e);
      }
    });
  }

  const handleSave = async () => {
    try {
      setIsLoading(true);

      // Call handleFileUpload and wait for it to complete
      await handleFileUpload();

      // Proceed to the API call after upload
      const payload = {
        isActive: showCircularProgressBar,
        title: exerciseName,
        description: description,
        temporaryVideoS3Key: media,
      };
      const response = await makeApiCall(routes.exercise.add, "POST", payload);
      if (response.status) {
        navigate("/exercises");
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Box sx={{ mx: "4%" }}>
      <LoadingBackdrop isLoading={isLoading} />

      <Grid display="flex" justifyContent={"space-between"} mt={5}>
        <Box>
          <Typography
            textAlign={"left"}
            fontSize={fonts.heading1}
            fontWeight={600}
            width={"200px"}
            mt={0}
            sx={{
              background: "linear-gradient(90deg, #74D0A9 0%, #9388D3 93.63%);",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            Exercises
          </Typography>
          <Typography
            mt={0.5}
            textAlign={"left"}
            gutterBottom
            color={colors.gray400}
          >
            See all the users that are making use of the web app
          </Typography>
        </Box>
      </Grid>
      <Box display="flex" justifyContent={"space-between"}>
        <Box mt={3}>
          <Breadcrumbs>
            <Link
              color={colors.gray400}
              sx={{ textDecoration: "none" }}
              href="/exercises"
            >
              All Exercise
            </Link>

            <Typography color="text.secondary">
              {data?.title || "New Exercise"}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box>
          {!data && (
            <Button
              variant="contained"
              fontSize={fonts.heading4}
              size="large"
              endIcon={<CheckOutlinedIcon />}
              sx={{
                textTransform: "none",
                background: colors.teal400,
                color: colors.teal50,
                fontWeight: 400,
                height: "40px",
                width: "150px",
                px: 2,
                mr: 2,
              }}
              disabled={isSaveDisabled()}
              onClick={handleSave}
            >
              Save
            </Button>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          mt: 6,
          mb: 1,
        }}
      >
        <Typography>Available for users</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={showCircularProgressBar}
              onChange={handleCircularProgressBarToggle}
              disabled={!hasPermission(userRole, "UpdateExercises")}
              color="primary"
            />
          }
          sx={{ ml: 10 }}
        />
      </Box>
      <Typography mb={4} textAlign={"left"} color={colors.gray600}>
        Toggles the visibility for users when they are trying to add new
        exercises. If a user already has this exercise, this toggle will have no
        effect on their workout and they will continue to have this exercise
      </Typography>
      <DividerLine />
      <TextField
        label="Name"
        type="text"
        value={exerciseName}
        placeholder="Enter Exercise Name"
        onChange={handleExerciseNameChange}
        slotProps={{
          input: {
            // Adjust margin if needed
            startAdornment: <AcuteIcon />,
          },
        }}
        sx={{
          mt: 0,
          width: {
            lg: "50%",
            md: "60%",
          },
          display: "flex",
          justifyContent: "start",
        }}
        helperText="Publicly visible and searchable display name "
      />
      <TextField
        multiline
        rows={4}
        label="Description (Optional)"
        type="text"
        variant="outlined"
        value={description}
        placeholder="Enter Description"
        onChange={handleDescriptionChange}
        sx={{
          mt: 4,
          width: {
            sm: "100%", // 70% for small screens
            md: "100%", // 50% for medium screens and above
            lg: "50%", // 50% for medium screens and above
          },
          display: "flex",
          justifyContent: "left",
          textAlign: "left",
          borderColor: colors.gray500,
        }}
      />

      <Box mt={6}>
        {isProcessing && (
          <Alert
            severity="success"
            iconMapping={{
              success: (
                <InfoOutlinedIcon
                  fontSize="inherit"
                  sx={{
                    color: colors.purple,
                  }}
                />
              ),
            }}
            sx={{
              background: colors.gray50,
              color: colors.purple,
              border: "2px solid",
              borderColor: colors.purple,
              textAlign: "left",
            }}
          >
            This video is still processing, once it’s done you’ll be able to
            view it
          </Alert>
        )}
        {!isProcessing && (
          <FileUploader
            showPreviousUploads={false}
            // typeImage={true}
            onFileChange={handleFileChange}
            value={media}
            isEdit={id ? true : false}
          />
        )}
      </Box>
    </Box>
  );
};

export default AddExercise;
