import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useSnackbar } from "../components/utils/SnackbarProvider";

const useApiRequest = () => {
  const navigate = useNavigate();
  const showSnackbar = useSnackbar(); // Use the global Snackbar
  const location = useLocation(); // Get the current location (route)
  const storedUser = JSON.parse(localStorage.getItem("user"));

  const makeApiCall = useCallback(
    async (endpoint, method, body = null, showToast = "hide", headers = {}) => {
      const options = {
        method,
        headers: {
          "Content-Type": "application/json",
          ...headers,
          ...(storedUser?.token && {
            Authorization: `Bearer ${storedUser?.token}`,
          }),
        },
        body: body ? JSON.stringify(body) : undefined,
      };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}${endpoint}`,
          options
        );
        if (response.status === 401) {
          const responseData = await response.json();

          if (location.pathname !== "/login" && location.pathname !== "/") {
            localStorage.removeItem("user");
            navigate("/");
          }

          return responseData; // Return the response data if needed
        }
        if (response.status === 404) {
          navigate("/");
          showSnackbar(responseData.message, "error");
        }
        if (response.status === 500) {
          navigate("/");
          showSnackbar(responseData.message, "error");
        }
        const responseData = await response.json();
        if (!response.ok) {
          showSnackbar(responseData.message, "error");
        } else {
          if (showToast === "show") {
            showSnackbar(responseData.message, "success"); // Show success snackbar
          }
        }

        return responseData;
      } catch (error) {
        showSnackbar(error.message, "error");
      }
    },
    [navigate]
  );

  return { makeApiCall };
};

export default useApiRequest;
